<template>
  <div ref="main" v-if="data !== null">
    <!--    <div ref="chart" style="width: 100%; height: 500px;">-->

    <!--    </div>-->
    <!-- title -->
    <table style="width: 100%; height: 40px">
      <tbody style="width: 100%">
      <tr>
        <td>
          <p v-if="data.resource.data !== null" class="title">
            {{ data.resource.data.resourceName }}
          </p>
        </td>
        <td style="text-align: right">
          <el-checkbox v-model="autoRefresh" @change="setRefresh">自动刷新</el-checkbox>
        </td>
      </tr>
      </tbody>
    </table>
    <!-- main -->
    <div v-if="reqFinished" class="main-container">
      <!-- item group -->
      <el-card v-for="ig in data.itemGroup.data" class="box">
        <div slot="header">
          <span>{{ ig.groupName }}</span>
        </div>
        <div>
          <div v-if="ig.isTable === 0 && data.item.dataGroupInd.hasOwnProperty(ig.groupId)">
            <el-table
                :data="data.item.dataGroupInd[ig.groupId]"
                size="small"
            >
              <el-table-column prop="itemName" label="指标"></el-table-column>
              <el-table-column prop="data" label="数据"></el-table-column>
              <el-table-column prop="recordTime" label="更新时间" width="200"></el-table-column>
              <el-table-column label="操作" width="100"><!--功能-->
                <template slot-scope="scope">
                  <el-button size="small" type="text" @click="data.dialog.days = 1; viewDataDialog(scope.row, false)">
                    查看
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-if="ig.isTable === 1 && data.item.dataGroupInd.hasOwnProperty(ig.groupId)">
            <el-table
                :data="ig['dataArr']"
                size="small"
            >
              <el-table-column v-for="col in ig['column']" :label="col[1]">
                <template slot="header" slot-scope="scope">
                  <span v-if="col[0] !== 'id'" style="cursor: pointer"
                        @click="data.dialog.days = 1; viewDataDialog(col[2], true)">
                    {{ col[1] }}
                  </span>
                  <span v-else> {{ col[1] }}</span>
                </template>
                <template slot-scope="scope">
                  <el-tooltip v-if="col[0] !== 'id'" class="item" effect="dark" :content="scope.row[col[0]][1]"
                              placement="top">
                    <span>{{ scope.row[col[0]][0] }}</span>
                  </el-tooltip>
                  <span v-else> {{ scope.row[col[0]] }} </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="data.dialog.title"
        :visible.sync="data.dialog.visible"
        :destroy-on-close="true"
        :fullscreen="true"
    >
      <div class="dialog-body" :style="'height: ' + data.dialog.dialogHeight">
        <div v-if="!data.dialog.isTable && data.dialog.type === 0">
          <el-table
              :height="data.dialog.dialogHeight"
              :data="data.dialog.data"
              v-loading="data.dialog.loading"
          >
            <el-table-column label="数据" prop="data"></el-table-column>
            <el-table-column label="记录时间" prop="recordTime"></el-table-column>
          </el-table>
        </div>
        <div v-if="!data.dialog.isTable && data.dialog.type !== 0">
          <div style="width: 100%; height: 100%;" ref="chart-noTable"></div>
        </div>
        <div v-if="data.dialog.isTable && data.dialog.type === 0">
          table text
        </div>
        <div v-if="data.dialog.isTable && data.dialog.type !== 0">
          <div style="width: 100%; height: 100%;" ref="chart-table"></div>
        </div>
      </div>
      <span slot="footer">
        <el-button v-for="i in [1, 3, 5, 7, 30]" size="small" @click="setDays(i)">{{ i }} 天</el-button>
        <el-button v-if="data.dialog.isTable && data.dialog.type !== 0" type="primary"
                   @click="chartLegendAll(0)">全部隐藏</el-button>
        <el-button v-if="data.dialog.isTable && data.dialog.type !== 0" type="primary"
                   @click="chartLegendAll(1)">全部显示</el-button>
        <el-button @click="data.dialog.visible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import indexMonitorDataApis from "@/apis/index-monitor/index-monitor-data-apis";
import indexMonitorItemApis from "@/apis/index-monitor/index-monitor-item-apis";

export default {
  name: 'IndexMonitorResourceData',
  data() {
    return {
      initData: JSON.stringify({
        resource: {
          id: null,
          data: null,
        },
        template: {
          id: null,
          data: null,
        },
        itemGroup: {
          data: null,
          dataGroupInd: null,
        },
        item: {
          data: null,
          dataGroupInd: null,
        },
        monitor: {
          data: null,
          dataItemInd: null,
        },
        dialog: {
          title: '',
          visible: false,
          data: null,
          loading: null,
          isTable: false,
          type: null,
          tableCol: null,
          pageNo: null,
          pageSize: null,
          dialogHeight: null,
          chart: null,
          chartData: null,
          itemRow: null,
          days: 1,
        }
      }),
      data: null,
      reqFinished: false,
      timer: null,
      autoRefresh: false,
      resizeEvFunc: () => {
        this.dialogTableHeight();
      }
    }
  },
  methods: {
    init() {
      if (this.timer !== null) {
        clearInterval(this.timer);
      }
      this.reqFinished = false;
      this.$set(this, 'data', JSON.parse(this.initData));
      this.data.resource.id = parseInt(this.$route.params.id);
      this.getResource(true);
      // this.autoRefresh = true;
      // this.timer = setInterval(() => {
      //   this.asyncGetAllData();
      // }, 5000);
    },
    getResource(next = false) {
      indexMonitorDataApis.getResourceByResourceId(this.data.resource.id).then(data => {
        if (data === null || data['data'] === null) {
          return;
        }
        this.$set(this.data.resource, 'data', data['data']);
        this.data.template.id = this.data.resource.data['templateId'];
        console.log('resource', this.data.resource.data);
        if (next) {
          this.asyncGetAllData();
        }
      });
    },
    // 格式化数据
    dataFormat(dataType, data) {
      switch (dataType) {
        case 1:
          return Number(data);
        case 2:
          return parseFloat((Number(data) * 100).toFixed(2)) + "%";
        case 3:
          return this.humanSize(Number(data));
      }
      return data;
    },
    humanSize(num) {
      const unit = ["", "K", "M", "G", "T", "P", "E", "Z"];
      for (let i = 0; i < unit.length; i++) {
        if (Math.abs(num) < 1024) {
          return parseFloat(num.toFixed(2)) + unit[i];
        }
        num = num / 1024;
      }
    },
    // 统一请求返回
    asyncGetAllData() {
      const func = [
        indexMonitorItemApis.getItemGroup(this.data.template.id),
        indexMonitorDataApis.getItemByTemplateId(this.data.template.id),
        indexMonitorDataApis.getLatestData(this.data.resource.id),
      ];
      const resultFunc = [
        this.getItemGroup(),
        this.getItem(),
        this.getLatestData(),
      ];
      if (func.length !== resultFunc.length) {
        console.error("error async func");
        return;
      }
      Promise.all(func).then(data => {
        for (let i = 0; i < data.length; i++) {
          resultFunc[i](data[i]);
        }
        this.setItemLatestData();
        this.reqFinished = true;
      });
    },

    getItemGroup() {
      return data => {
        if (data === null) {
          return;
        }
        this.$set(this.data.itemGroup, 'dataGroupInd', {});
        for (let i = 0; i < data['data'].length; i++) {
          this.$set(this.data.itemGroup.dataGroupInd, data['data'][i]['groupId'], data['data'][i]);
        }
        this.$set(this.data.itemGroup, 'data', data['data']);
        console.log('itemGroup', this.data.itemGroup.data);
        console.log('itemGroupInd', this.data.itemGroup.dataGroupInd);
      };
    },
    getItem() {
      return data => {
        if (data === null) {
          return;
        }
        this.$set(this.data.item, 'dataGroupInd', {});
        for (let i = 0; i < data['data'].length; i++) {
          const row = data['data'][i];
          if (!this.data.item.dataGroupInd.hasOwnProperty(row['itemGroupId'])) {
            this.$set(this.data.item.dataGroupInd, row['itemGroupId'], []);
          }
          this.data.item.dataGroupInd[row['itemGroupId']].push(row);
        }
        console.log(this.data.item.dataGroupInd)
        this.$set(this.data.item, 'data', data['data']);
        console.log('item', this.data.item.data);
      };
    },
    getLatestData() {
      return data => {
        if (data === null) {
          return;
        }
        this.$set(this.data.monitor, 'dataItemInd', {});
        for (let i = 0; i < data['data'].length; i++) {
          this.$set(this.data.monitor.dataItemInd, data['data'][i]['itemId'], data['data'][i]);
        }
        this.$set(this.data.monitor, 'data', data['data']);
        console.log('latest data', this.data.monitor.data);
      };
    },
    setItemLatestData() {
      const res = this.data.item.dataGroupInd;
      const groupInd = this.data.itemGroup.dataGroupInd;
      const resKeys = Object.keys(res);
      for (let i = 0; i < resKeys.length; i++) {
        const rowGroupInd = groupInd[resKeys[i]];
        for (let j = 0; j < res[resKeys[i]].length; j++) {
          if (rowGroupInd['isTable'] === 0) {
            if (this.data.monitor.dataItemInd.hasOwnProperty(res[resKeys[i]][j]['itemId'])) {
              res[resKeys[i]][j]['data'] = this.dataFormat(res[resKeys[i]][j]['itemType'], this.data.monitor.dataItemInd[res[resKeys[i]][j]['itemId']]['data']);
              res[resKeys[i]][j]['recordTime'] = this.data.monitor.dataItemInd[res[resKeys[i]][j]['itemId']]['recordTime'];
            } else {
              res[resKeys[i]][j]['data'] = null;
              res[resKeys[i]][j]['recordTime'] = null;
            }
          } else {
            if (this.data.monitor.dataItemInd.hasOwnProperty(res[resKeys[i]][j]['itemId'])) {
              const columnData = this.data.monitor.dataItemInd[res[resKeys[i]][j]['itemId']]['data'];
              const columnType = res[resKeys[i]][j]['itemType'];
              const columnRecordTime = this.data.monitor.dataItemInd[res[resKeys[i]][j]['itemId']]['recordTime'];
              // res[resKeys[i]][j]['recordTime'] = this.data.monitor.dataItemInd[res[resKeys[i]][j]['itemId']]['recordTime'];
              let columnDataObj;
              try {
                columnDataObj = JSON.parse(columnData);
              } catch (e) {
                continue;
              }
              if (!rowGroupInd.hasOwnProperty('ind')) {
                rowGroupInd['ind'] = [];
              }
              if (!rowGroupInd.hasOwnProperty('column')) {
                rowGroupInd['column'] = [['id', columnDataObj['idName'], null]];
              }
              if (!rowGroupInd.hasOwnProperty('data')) {
                rowGroupInd['data'] = {}; // key -> data
              }
              for (let k = 0; k < columnDataObj['ind'].length; k++) {
                if (!rowGroupInd['ind'].includes(columnDataObj['ind'][k])) {
                  rowGroupInd['ind'].push(columnDataObj['ind'][k]);
                }
                if (!Object.keys(rowGroupInd['data']).includes(columnDataObj['ind'][k])) {
                  rowGroupInd['data'][columnDataObj['ind'][k]] = {};
                }
              }
              const keyName = res[resKeys[i]][j]['itemKeyName'].split('.')[1];
              const itemName = res[resKeys[i]][j]['itemName'];
              rowGroupInd['column'].push([keyName, itemName, res[resKeys[i]][j]]);
              if (
                  !Object.keys(columnDataObj).includes(keyName)
                  || !Object.keys(columnDataObj).includes('ind')
                  || columnDataObj['ind'].length !== columnDataObj[keyName].length) {
                continue;
              }
              for (let k = 0; k < columnDataObj['ind'].length; k++) {
                rowGroupInd['data'][columnDataObj['ind'][k]][keyName] = [this.dataFormat(columnType, columnDataObj[keyName][k]), columnRecordTime];
              }
            }
          }
        }
        if (rowGroupInd['isTable'] === 1 && rowGroupInd.hasOwnProperty('data') && rowGroupInd['data'] !== null) {
          // data to array
          rowGroupInd['dataArr'] = [];
          for (let j = 0; j < rowGroupInd['ind'].length; j++) {
            const rowId = rowGroupInd['ind'][j];
            rowGroupInd['dataArr'].push({
              'row': rowGroupInd,
              'id': rowId,
            });
            for (let k = 1; k < rowGroupInd['column'].length; k++) {
              const columnName = rowGroupInd['column'][k][0];
              rowGroupInd['dataArr'][j][columnName] = rowGroupInd['data'][rowId][columnName];
            }
          }
        }
      }
    },
    viewDataDialog(row, isTable) {
      console.log(row);
      this.data.dialog.data = null;
      this.data.dialog.itemRow = row;
      this.data.dialog.isTable = isTable;
      if (isTable) {
        this.data.dialog.tableCol = row['itemKeyName'].split('.')[1];
      }
      this.data.dialog.type = row['itemType'];
      this.data.dialog.visible = true;
      if (row['itemType'] === 0) {
        this.data.dialog.pageNo = 1;
        this.data.dialog.pageSize = 20;
        this.getDataByItemId(row['itemId'], this.data.dialog.days, true, 1, -1);
      } else {
        this.getDataByItemId(row['itemId'], this.data.dialog.days, false, 1, -1);
      }
    },
    getDataByItemId(itemId, days, desc, page = 1, pageSize = -1) {
      this.data.dialog.loading = true;
      const resourceId = this.data.resource.id;
      indexMonitorDataApis.getData(page, pageSize, resourceId, itemId, days, desc).then(data => {
        this.data.dialog.loading = false;
        console.log(this.data, this.data.dialog);
        console.log(data);
        if (data === null) {
          return;
        }
        this.data.dialog.data = data['data']['rows'];
        this.detailDataFormat();
      });
    },
    detailDataFormat() {
      const type = this.data.dialog.type;
      const data = this.data.dialog.data;
      const isTable = this.data.dialog.isTable;
      if (type === 0) {
        return;
      }

      if (!isTable) {
        // no table
        this.data.dialog.chartData = {
          title: {
            text: this.data.dialog.itemRow['itemName'] + "（" + this.data.dialog.days + "天）"
          },
          legend: {
            data: [this.data.dialog.itemRow['itemName']]
          },
          series: [{
            name: this.data.dialog.itemRow['itemName'],
            type: 'line',
            data: [],
            showSymbol: false
          }],
          tooltip: {
            formatter: (param) => {
              param = param[0];
              return param['data'][0] + "<br/>" + param['seriesName'] + "：" + this.dataFormat(type, param['data'][1]);
            }
          },
          yAxis: {
            axisLabel: {
              show: true,
              formatter: (param) => {
                return this.dataFormat(type, param);
              }
            }
          }
        };
        for (let i = 0; i < data.length; i++) {
          this.data.dialog.chartData['series'][0]['data'].push([data[i]['recordTime'], Number(data[i]['data'])]);
        }
        console.log(this.data.dialog.chartData);
        this.checkDetailRequestFinished();
        this.data.dialog.chart.setOption(this.data.dialog.chartData);
      } else {
        // is table
        this.data.dialog.chartData = {
          title: {
            text: this.data.dialog.itemRow['itemName'] + "（" + this.data.dialog.days + "天）"
          },
          legend: {
            data: null,
            selectedMode: true,
          },
          series: [],
          tooltip: {
            formatter: (param) => {
              let txt = param[0]['data'][0] + "<br/>";
              for (let i = 0; i < param.length; i++) {
                txt += param[i]['seriesName'] + "：" + this.dataFormat(type, param[i]['data'][1]);
                if (i < param.length) {
                  txt += "<br/>";
                }
              }
              return txt;
            }
          },
          yAxis: {
            axisLabel: {
              show: true,
              formatter: (param) => {
                return this.dataFormat(type, param);
              }
            }
          }
        };
        const ind = [];
        const key = this.data.dialog.itemRow['itemKeyName'].split('.')[1];
        console.log('key', key);
        console.log(this.data.dialog.itemRow);
        const indIndex = {};
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            console.log(data[i]);
          }
          const obj = JSON.parse(data[i]['data']);
          if (!Object.keys(obj).includes(key)) {
            console.log('no include key', key);
            continue;
          }
          // ind
          for (let j = 0; j < obj['ind'].length; j++) {
            if (!ind.includes(obj['ind'][j])) {
              ind.push(obj['ind'][j]);
            }
          }

          // key
          if (obj['ind'].length !== obj[key].length) {
            console.log('key error');
            continue;
          }
          for (let j = 0; j < obj['ind'].length; j++) {
            if (j === 0 && i === 0) {
              console.log(obj['ind'][j], obj[key][j]);
            }
            if (!Object.keys(indIndex).includes(obj['ind'][j])) {
              indIndex[obj['ind'][j]] = {
                name: obj['ind'][j],
                type: 'line',
                data: [],
                showSymbol: false
              };
              this.data.dialog.chartData.series.push(indIndex[obj['ind'][j]]);
            }
            indIndex[obj['ind'][j]]['data'].push(
                [data[i]['recordTime'], obj[key][j]]
            );
          }
        }
        this.data.dialog.chartData.legend.data = ind;

        // set options
        this.checkDetailRequestFinished();
        this.data.dialog.chart.setOption(this.data.dialog.chartData);
      }
    },
    checkDetailRequestFinished(loading) {
      if (loading === true || loading === null) {
        return;
      }
      if (this.data.dialog.type === 0) {
        return;
      }
      const options = this.chartDefaultOptions(this.data.dialog.itemRow['itemName']);
      if (this.data.dialog.isTable) {
        this.data.dialog.chart = this.initChart(this.$refs["chart-table"], options);
      } else {
        this.data.dialog.chart = this.initChart(this.$refs["chart-noTable"], options);
      }
      this.data.dialog.chart.setOption(this.chartOptions(this.data.dialog.type));
    },
    initChart(el, options) {
      console.log(el, options);
      const myChart = this.$echarts.init(el, 'light');
      myChart.setOption(options);
      return myChart;
    },
    chartDefaultOptions(title) {
      return {
        title: {
          text: title
        },
        legend: {
          selectedMode: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          type: 'time',
        },
        yAxis: {},
        series: [{
          name: '',
          type: 'line',
          data: [],
        }],
        dataZoom: [
          {
            type: 'slider',
            xAxisIndex: [0],
            start: 80,
            end: 100
          }
        ]
      };
    },
    chartOptions(type) {
      switch (type) {
        case 1:
          return {};
        case 2:
          return {};
        case 3:
          return {};
      }
    },
    setRefresh() {
      if (this.autoRefresh) {
        if (this.timer !== null) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.asyncGetAllData();
        }, 5000);
      } else {
        clearInterval(this.timer);
      }
    },
    dialogTableHeight() {
      if (!this.$refs.main) {
        this.data.dialog.dialogHeight = "0";
      } else {
        this.data.dialog.dialogHeight = this.$refs.main.offsetHeight - 60 + "px";
      }
    },
    chartLegendAll(type) {
      const series = {};
      const data = this.data.dialog.chartData;
      for (let i = 0; i < data['series'].length; i++) {
        series[data['series'][i]['name']] = (type === 1);
      }
      data.legend.selected = series;
      this.data.dialog.chart.setOption(data);
    },
    setDays(days) {
      this.data.dialog.days = days;
      this.viewDataDialog(this.data.dialog.itemRow, this.data.dialog.isTable);
    }
  },
  computed: {},
  watch: {
    '$route.params.id': 'init',
    // 'data.dialog.loading': 'checkDetailRequestFinished',
  },
  mounted() {
    this.init();
    window.$vueIMRD = this;
    console.log(this.$echarts);
    this.$nextTick(() => {
      this.dialogTableHeight();
    });

    window.addEventListener('resize', this.resizeEvFunc);
    this.resizeEvFunc();
  },
  beforeDestroy() {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    window.removeEventListener('resize', this.resizeEvFunc);
  }
}
</script>

<style scoped>
.dialog-body > div {
  height: 100%;
}

.title {
  color: #606266;
  font-size: 16px;
  line-height: 40px;
  height: 35px;
}

.main-container {
  display: flex;
  height: calc(100% - 40px);
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
}

.box {
  width: 100%;
  margin: 15px 0;
}
</style>