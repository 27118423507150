<template>
  <div class="index-body">
    <div
        style="width: 200px; height: 100%; float: left; overflow: hidden; border: 1px solid #e6e6e6; border-radius: 5px;">
      <div style="height: 100%; width: 218px; overflow-y: scroll; padding-right: 17px; margin-right: -17px;">
        <el-menu
            v-if="initFinished"
            style="height: 100%"
            :default-active="navActive"
            router
            :default-openeds="navMenu.defaultOpen"
        >
          <el-menu-item index="/index/itsm/new">
            <template slot="title">
              <i class="el-icon-document-add"></i>
              <span>新建</span>
            </template>
          </el-menu-item>
          <el-submenu index="admin-order" v-if="roleId !== 1">
            <template slot="title">
              <i class="el-icon-document-copy"></i>
              <span>工单处理</span>
            </template>
            <el-menu-item index="/index/itsm/admin/order/pending">待办</el-menu-item>
            <el-menu-item index="/index/itsm/admin/order/done">已办</el-menu-item>
          </el-submenu>
          <el-submenu index="order">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>我的工单</span>
            </template>
            <el-menu-item index="/index/itsm/order/running">进行中</el-menu-item>
            <el-menu-item index="/index/itsm/order/return">已退回</el-menu-item>
            <el-menu-item index="/index/itsm/order/comment">待评价</el-menu-item>
            <el-menu-item index="/index/itsm/order/end">已结束</el-menu-item>
          </el-submenu>
          <el-submenu index="settings" v-if="roleId !== 1">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>设置</span>
            </template>
            <el-menu-item index="/index/itsm/admin/template">模板</el-menu-item>

          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="main">
      <router-view style="padding: 5px; height: calc(100% - 10px); width: calc(100% - 10px); overflow-y: auto"/>
    </div>
  </div>
</template>
<script>
import indexGlobalApis from "@/apis/index-global-apis";

export default {
  name: 'IndexITSM',
  data() {
    return {
      navMenu: {
        defaultOpen: []
      },
      roleId: null,
      initFinished: false,
    };
  },
  methods: {
    checkPath() {
      if (this.$route.path === '/index/itsm') {
        this.$router.replace('/index/itsm/order/running');
      }
    },
    getUserInfo() {
      indexGlobalApis.userInfo().then(data => {
        if (data === null) {
          return;
        }
        this.roleId = data['data']['roleId'];
        this.initFinished = true;
      });
    }
  },
  watch: {
    '$route.path': 'checkPath'
  },
  computed: {
    navActive() {
      const path = this.$route.path;
      if (path.indexOf("/index/itsm/admin/template") !== -1) {
        return "/index/itsm/admin/template";
      }
      return path;
    }
  },
  mounted() {
    this.initFinished = false;
    this.getUserInfo();
    this.checkPath();
  }
}
</script>

<style scoped>
.main {
  float: right;
  height: 100%;
  width: calc(100% - 220px);
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}
</style>