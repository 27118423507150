<template>
  <div ref="body">
    <div style="height: 40px">
      <el-button size="small" type="success" @click="addTemplateDialog">新增</el-button>
      <el-button size="small" type="primary" @click="getTemplate">刷新</el-button>
    </div>
    <div>
      <el-table
          ref="table"
          :height="template.table.height"
          :data="template.table.data"
          v-loading="template.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="templateName" label="模板名称"></el-table-column>

        <el-table-column label="操作"><!-- 功能 -->
          <template slot-scope="scope">
            <el-button size="small" type="text"
                       @click="$router.push('/index/monitor/template/'+scope.row['templateId'])">查看
            </el-button>
            <el-button size="small" type="text" @click="setTemplateDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delTemplate(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <!-- template dialog -->
    <el-dialog
        :close-on-click-modal="false"
        :title="template.dialog.title"
        :visible.sync="template.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-input v-model="template.dialog.templateName" placeholder="模板名称"></el-input>
      </span>
      <span slot="footer">
        <el-button @click="template.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="templateDialogConfirm" :loading="template.dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import indexMonitorTemplateApis from "@/apis/index-monitor/index-monitor-template-apis";

export default {
  name: 'IndexMonitorTemplate',
  data() {
    return {
      template: {
        table: {
          height: null,
          loading: false,
          data: null
        },
        dialog: {
          title: '',
          visible: false,
          templateName: '',
          rowInd: null
        }
      }
    }
  },
  methods: {
    getTemplate() {
      indexMonitorTemplateApis.getTemplate().then(data => {
        if (data === null) {
          return;
        }
        console.log(data);
        this.$set(this.template.table, 'data', data['data']);
      });
    },
    templateDialog(title) {
      this.template.dialog.title = title;
      this.template.dialog.visible = true;
    },
    templateDialogConfirm() {
      switch (this.template.dialog.title) {
        case "新增":
          this.addTemplate();
          break;
        case "修改":
          this.setTemplate();
          break;
      }
    },
    addTemplateDialog() {
      this.template.dialog.templateName = '';
      this.templateDialog('新增');
    },
    addTemplate() {
      if (this.template.dialog.templateName === '') {
        this.$message.error("“模板名称”不能为空！");
        return;
      }
      indexMonitorTemplateApis.addTemplate(this.template.dialog.templateName).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("新增成功！");
        this.getTemplate();
        this.template.dialog.visible = false;
      })
    },
    setTemplateDialog(row) {
      this.template.dialog.templateName = row['templateName'];
      this.template.dialog.rowInd = row;
      this.templateDialog("修改");
    },
    setTemplate() {
      if (this.template.dialog.templateName === '') {
        this.$message.error("“模板名称”不能为空！");
        return;
      }
      indexMonitorTemplateApis.setTemplate(this.template.dialog.rowInd['templateId'], this.template.dialog.templateName).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功！");
        this.getTemplate();
        this.template.dialog.visible = false;
      })
    },
    delTemplate(row) {
      this.$confirm("你确定要删除“" + row['templateName'] + "”吗？").then(() => {
        indexMonitorTemplateApis.delTemplate(row['templateId']).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功！");
          this.getTemplate();
          this.template.dialog.visible = false;
        });
      }).catch(() => {
      });
    },
    tableResize() {
      if (!this.$refs.body) {
        this.template.table.height = "0";
      } else {
        this.template.table.height = this.$refs.body.offsetHeight - 60 + "px";
      }
    },
  },
  mounted() {
    this.getTemplate();
    window.addEventListener('resize', this.tableResize);
    this.tableResize();
  }
}
</script>

<style scoped>

</style>