<template>
  <div>
    <div
        style="float: left; height: calc(100% - 22px); width: 180px; border: 1px solid #e6e6e6; border-radius: 5px; margin: 10px;">
      <el-input size="small" v-model="filterText" placeholder="查询..."
                style="margin: 10px; width: calc(100% - 20px);"></el-input>
      <el-tree
          :data="typeData"
          node-key="typeId"
          @node-click="treeClick"
          ref="tree"
          :filter-node-method="filterNode"
      >
      </el-tree>
    </div>
    <div ref="tableParent"
         style="float: right; height: calc(100% - 22px); width: calc(100% - 270px); padding: 0 20px; border: 1px solid #e6e6e6; margin: 10px; border-radius: 5px">
      <div style="height: 50px; line-height: 50px">
        <!-- 工具栏 -->
        <div style="float: left; line-height: 50px;">
          <el-breadcrumb separator="/">
            <div v-if="sec === ''">
              <el-breadcrumb-item>{{ fir }}</el-breadcrumb-item>
            </div>
            <div v-else>
              <el-breadcrumb-item>{{ fir }}</el-breadcrumb-item>
              <el-breadcrumb-item>{{ sec }}</el-breadcrumb-item>
            </div>
          </el-breadcrumb>
        </div>
        <div style="float: right">
          <el-button size="small" type="success" @click="addResourceDialog">新增资源</el-button>
        </div>
      </div>
      <el-table :height="resource.table.height" :data="resourceData">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column prop="resourceName" label="资源名称" width="250"></el-table-column>
        <el-table-column prop="groupName" label="资源组" width="250"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作" width="150"><!--功能-->
          <template slot-scope="scope">
            <el-button size="small" type="text"
                       @click="$router.push('/index/resource/data/details/'+scope.row.resourceId)">查看
            </el-button>
            <el-button size="small" type="text" @click="setResourceDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delResource(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="float: right; margin-top: 10px;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="resource.table.pageCurrent"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="resource.table.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="resource.table.pageTotal"
            background>
        </el-pagination>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="resource.dialog.title"
        :visible.sync="resource.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-form label-width="100px">
          <el-form-item label="资源名称">
            <el-input style="width: 95%;" placeholder="请输入" v-model="resource.dialog.input.resourceName"></el-input>
          </el-form-item>
          <el-form-item label="资源组">
            <el-select style="width: 95%;" v-model="resource.dialog.input.resourceGroup">
                <el-option
                    v-for="row in group.groups"
                    :key="row.groupId"
                    :label="row.groupName"
                    :value="row.groupId"
                >
                </el-option>
            </el-select>
            <p style="color: red;font-weight: bold"
               v-show="resource.dialog.title==='修改'">修改资源组会清空原有填写数据，请谨慎操作</p>
          </el-form-item>
          <el-form-item label="备注(可选)">
            <el-input style="width: 95%;" placeholder="请输入" v-model="resource.dialog.input.remark"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="resource.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="dialogEvent" :loading="resource.dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import indexResourceTypeApis from '@/apis/index-resource/index-resource-type-apis';
import indexResourceAttributeApis from "@/apis/index-resource/index-resource-attribute-apis";
import indexResourceDataApis from "@/apis/index-resource/index-resource-data-apis";

export default {
  name: 'IndexResourceData',
  data() {
    return {
      filterText: '',
      typeData: [],
      typeDataRootIndex: {},
      selectedTypeId: -1,
      group: {
        groups: [],
        groupsIndexById: {},
      },
      resource: {
        table: {
          height: null,
          pageCurrent: 1,
          pageSize: 20,
          pageTotal: 0,
          loading: false,
        },
        data: null,
        dialog: {
          title: '',
          visible: false,
          input: {
            resourceName: null,
            resourceGroup: null,
            remark: null,
            resourceId: null
          },
          loading: false
        }
      },
      fir: "",
      sec: "",
    };
  },
  methods: {
    getType() {
      this.typeData = [];
      indexResourceTypeApis.getType().then((data) => {
        if (data === null) {
          return;
        }
        let rootType = [];
        let childType = [];
        const row = data['data'];
        for (let i = 0; i < row.length; i++) {
          if (row[i]['parentTypeId'] === -1) {
            rootType.push(row[i]);
          } else {
            childType.push(row[i]);
          }
          row[i]['label'] = row[i]['typeName'];
        }
        for (let i = 0; i < rootType.length; i++) {
          this.typeData.push(rootType[i]);
          this.typeDataRootIndex[rootType[i]['typeId']] = this.typeData[this.typeData.length - 1];
          this.typeDataRootIndex[rootType[i]['typeId']].children = [];
        }
        const typeRootIndexKey = Object.keys(this.typeDataRootIndex);
        for (let i = 0; i < childType.length; i++) {
          if (!typeRootIndexKey.includes(childType[i]['parentTypeId'].toString())) {
            console.log('data error: ', childType[i]);
            continue;
          }
          this.typeDataRootIndex[childType[i]['parentTypeId']].children.push(childType[i]);
        }
        if (this.typeData.length > 0) {
          this.treeClick(this.typeData[0]);
        }
      });
    },
    treeClick(node) {
      if (node['parentTypeId'] === -1) {
        this.fir = node['typeName'];
        this.sec = '';
      } else {
        this.fir = this.typeDataRootIndex[node['parentTypeId']]['typeName'];
        this.sec = node['typeName'];
      }
      this.selectedTypeId = node['typeId'];
      this.getResource();
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    },
    getResourceGroup() {
      indexResourceAttributeApis.getGroups().then(data => {
        if (data === null) {
          return;
        }
        this.group.groups = data.data;
        if (this.group.groups.length !== 0) {
          for (let i = 0; i < this.group.groups.length; i++) {
            this.group.groupsIndexById[this.group.groups[i]['groupId']] = this.group.groups[i]['groupName'];
          }
        }
      });
    },
    getResource() {
      this.resource.table.loading = true;
      this.resource.data = null;

      indexResourceDataApis.getResource(this.selectedTypeId, this.resource.table.pageCurrent, this.resource.table.pageSize).then(res => {
        if (res === null) {
          return;
        }
        const data = res['data'];
        this.resource.data = data['rows'];
        this.resource.table.pageTotal = data['totalSize'];
      });
      this.resource.table.loading = false;
    },
    resourceTableResize() {
      if (!this.$refs.tableParent) {
        this.resource.table.height = "0";
      } else {
        this.resource.table.height = this.$refs.tableParent.offsetHeight - 100 + "px";
      }
    },
    handleSizeChange(size) {
      this.resource.table.pageSize = size;
      this.getResource();
    },
    handleCurrentChange(page) {
      this.resource.table.pageCurrent = page;
      this.getResource();
    },
    dialogEvent() {
      if (this.resource.dialog.title === '新增') {
        this.addResource();
      } else if (this.resource.dialog.title === '修改') {
        this.setResource();
      }
    },
    addResourceDialog() {
      const keys = Object.keys(this.resource.dialog.input);
      for (let i = 0; i < keys.length; i++) {
        this.resource.dialog.input[keys[i]] = (keys[i] === 'remark') ? '' : null;
      }
      this.resource.dialog.title = '新增';
      this.resource.dialog.visible = true;
    },
    setResourceDialog(row) {
      console.log(row);
      const keys = Object.keys(this.resource.dialog.input);
      for (let i = 0; i < keys.length; i++) {
        this.resource.dialog.input[keys[i]] = (keys[i] === 'remark') ? '' : null;
      }
      this.resource.dialog.title = '修改';
      this.resource.dialog.input.resourceName = row['resourceName'];
      this.resource.dialog.input.resourceGroup = row['groupId'];
      this.resource.dialog.input.remark = row['remark'];
      this.resource.dialog.input.resourceId = row['resourceId'];
      this.resource.dialog.visible = true;
    },
    addResource() {
      const input = this.resource.dialog.input;
      if (input.resourceName === null || input.resourceName === '') {
        this.$message.error('请输入资源名称');
        return;
      }
      if (input.resourceGroup === null) {
        this.$message.error('请选择资源组');
        return;
      }
      this.resource.dialog.loading = true;
      indexResourceDataApis.addResource(
          input.resourceName, this.selectedTypeId, input.resourceGroup, input.remark).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success('添加成功');
        this.resource.dialog.visible = false;
        this.getResource();
      });
      this.resource.dialog.loading = false;
    },
    setResource() {
      console.log(this.resource.dialog.input)
      const input = this.resource.dialog.input;
      if (!input.resourceName) {
        this.$message.error('请输入资源名称');
        return;
      }
      if (input.resourceGroup === null) {
        this.$message.error('请选择资源组');
        return;
      }
      indexResourceDataApis.setResource(
          input.resourceId, input.resourceName, this.selectedTypeId, input.resourceGroup, input.remark
      ).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success('修改成功');
        this.resource.dialog.visible = false;
        this.getResource();
      });
    },
    delResource(row) {
      this.$confirm('你确定要删除“' + row['resourceName'] + '”吗？').then(() => {
        indexResourceDataApis.delResource(row['resourceId']).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success('删除成功');
          this.getResource();
        })
      }).catch(() => {
      });
    }
  },
  mounted() {
    window.vueResourceData = this;
    this.resourceTableResize();
    window.addEventListener('resize', this.resourceTableResize);
    this.getResourceGroup();
    this.getType();
  },
  computed: {
    resourceData() {
      const data = this.resource.data;
      if (!data) {
        return data;
      }
      for (let i = 0; i < data.length; i++) {
        if (this.group.groupsIndexById[data[i]['groupId']]) {
          data[i]['groupName'] = this.group.groupsIndexById[data[i]['groupId']];
        }
      }
      return data;
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  }
}
</script>

<style scoped>
.el-breadcrumb {
  line-height: 50px;
}
</style>