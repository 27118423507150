<template>
  <div ref="body">
    <div style="height: 40px">
      <el-button size="small" type="success" @click="addAgentDialog">新增</el-button>
      <el-button size="small" @click="refreshData">刷新</el-button>
    </div>

    <div>
      <el-table
          ref="table"
          :height="agent.table.height"
          :data="agent.table.data"
          v-loading="agent.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="agentName" label="Agent名称"></el-table-column>
        <el-table-column prop="agentIP" label="IP地址"></el-table-column>

        <el-table-column label="操作"><!-- 功能 -->
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="setAgentDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delAgent(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          background
          @size-change="tableSizeChange"
          @current-change="tableCurrentChange"
          :current-page="agent.table.pageNo"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="agent.table.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px; height: 36px; float: right;"
          :total="agent.table.totalSize">
      </el-pagination>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="agent.dialog.title"
        :visible.sync="agent.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <el-form label-width="20">
        <el-form-item label="Agent 名称">
          <el-input v-model="agent.dialog.data.agentName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="Agent IP">
          <el-input v-model="agent.dialog.data.agentIP" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="Agent Key">
          <el-select placeholder="请选择" style="width: 100%" v-model="agent.dialog.data.agentKeyId">
            <el-option v-for="i in agentKey.ind" :label="i[1]['remark'] + ' | ' + i[1]['key']" :value="i[0]">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="agent.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="agentDialogConfirm" :loading="agent.dialog.loading">确定</el-button>
      </span>
    </el-dialog>
    <!-- TODO: 分页 -->
  </div>
</template>
<script>

import indexMonitorAgentApis from "@/apis/index-monitor/index-monitor-agent-apis";

export default {
  name: 'IndexMonitorAgent',
  data() {
    return {
      agent: {
        table: {
          pageNo: 1,
          pageSize: 20,
          totalSize: 0,
          filter: {},
          height: null,
          loading: false,
          data: null
        },
        dialog: {
          title: '',
          visible: false,
          data: {},
          dataTemplate: JSON.stringify({
            agentName: "",
            agentIP: "",
            agentKeyId: null
          }),
          rowInd: null
        }
      },
      agentKey: {
        data: null,
        ind: null,
      }
    };
  },
  methods: {
    refreshData() {
      this.agent.table.pageNo = 1;
      this.agent.table.pageSize = 20;
      this.getAgentKey();
      this.getAgent();
    },
    getAgent() {
      this.$refs.table.$el.children[2].scrollTop = 0;
      indexMonitorAgentApis.getAgent(this.agent.table.pageNo, this.agent.table.pageSize, this.agent.table.filter).then(data => {
        if (data === null) {
          return;
        }
        this.agent.table.totalSize = data['data']['totalSize'];
        this.$set(this.agent.table, 'data', data['data']['rows']);
      });
    },
    getAgentKey() {
      console.log(this.$refs.table.$el.children[2]);
      indexMonitorAgentApis.getAgentKey().then(data => {
        if (data == null) {
          return;
        }
        const keyInd = [];
        for (const k in data['data']) {

          keyInd.push([data['data'][k]['agentKeyId'], data['data'][k]]);
        }
        this.$set(this.agentKey, 'ind', keyInd);
        this.$set(this.agentKey, 'data', data['data']);
      });
    },
    agentDialog(title) {
      this.agent.dialog.title = title;
      this.agent.dialog.visible = true;
    },
    agentDialogConfirm() {
      switch (this.agent.dialog.title) {
        case "新增":
          this.addAgent();
          break;
        case "修改":
          this.setAgent();
          break;
      }
    },
    addAgentDialog() {
      this.agent.dialog.data = JSON.parse(this.agent.dialog.dataTemplate);
      this.agentDialog('新增');
    },
    addAgent() {
      const data = this.agent.dialog.data;
      if (data.agentName === "") {
        this.$message.error("“Agent 名称” 不能为空！");
        return;
      }
      if (data.agentIP === "") {
        this.$message.error("“Agent IP” 不能为空！");
        return;
      }
      if (data.agentKeyId === null) {
        this.$message.error("“Agent Key” 不能为空！")
        return;
      }
      indexMonitorAgentApis.addAgent(data.agentName, data.agentIP, data.agentKeyId).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("添加成功！");
        this.agent.dialog.visible = false;
        this.getAgent();
      });
    },
    setAgentDialog(row) {
      console.log(row);
      this.agent.dialog.data = JSON.parse(this.agent.dialog.dataTemplate);
      this.agent.dialog.data.agentName = row.agentName;
      this.agent.dialog.data.agentIP = row.agentIP;
      this.agent.dialog.data.agentKeyId = row.agentKeyId;
      this.agent.dialog.rowInd = row;
      this.agentDialog('修改');
    },
    setAgent() {
      const data = this.agent.dialog.data;
      if (data.agentName === "") {
        this.$message.error("“Agent 名称” 不能为空！");
        return;
      }
      if (data.agentIP === "") {
        this.$message.error("“Agent IP” 不能为空！");
        return;
      }
      if (data.agentKeyId === null) {
        this.$message.error("“Agent Key” 不能为空！")
        return;
      }

      indexMonitorAgentApis.setAgent(
          this.agent.dialog.rowInd.agentId,
          data.agentName, data.agentIP, data.agentKeyId
      ).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功！");
        this.agent.dialog.visible = false;
        this.getAgent();
      });
    },
    delAgent(row) {
      this.$confirm("确定要删除“" + row.agentName + "”吗？").then(() => {
        indexMonitorAgentApis.delAgent(row.agentId).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功！");
          this.getAgent();
        });
      }).catch(() => {
      });
    },
    tableSizeChange(size) {
      this.agent.table.pageSize = size;
      this.getAgent();
    },
    tableCurrentChange(current) {
      this.agent.table.pageNo = current;
      this.getAgent();
    },
    agentTableResize() {
      if (!this.$refs.body) {
        this.agent.table.height = "0";
      } else {
        this.agent.table.height = this.$refs.body.offsetHeight - 100 + "px";
      }
    },
  },
  mounted() {
    this.getAgentKey();
    this.getAgent();
    window.addEventListener('resize', this.agentTableResize);
    this.agentTableResize();
    window.$vueIMA = this;

  }
}
</script>

<style scoped>

</style>