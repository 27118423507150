<template>
  <div ref="body">
    <div style="height: 40px">
      <table style="width: 100%">
        <tbody style="width: 100%">
        <tr>
          <!-- 资源组 -->
          <td>
            <span
                style="color: #606266; font-size: 14px; margin-right: 5px; user-select: none; cursor: default">资源组</span>
            <el-button size="small" type="success" @click="addResourceGroupDialog">新增</el-button>
            <el-button size="small" type="primary" @click="setResourceGroupDialog">修改</el-button>
            <el-button size="small" type="danger" @click="delResourceGroupConfirm">删除</el-button>
            <el-button size="small" @click="getResourceGroup">刷新</el-button>
          </td>
          <!-- 资源 -->
          <td style="text-align: right">
            <span
                style="color: #606266; font-size: 14px; margin-right: 5px; user-select: none; cursor: default">资源</span>
            <el-button size="small" type="success" @click="addResourceDialog">新增</el-button>
            <el-button size="small" @click="getResource">刷新</el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <el-tabs v-model="resourceGroup.active" @tab-click="getResource" style="height: 50px;">
      <el-tab-pane v-for="v in resourceGroup.data" :label="v.groupName" :name="v.groupId.toString()">
      </el-tab-pane>
    </el-tabs>
    <el-table
        ref="table"
        :height="table.height"
        :data="resource.data"
        v-loading="table.loading"
    >
      <!--<el-table-column type="index" label="序号"></el-table-column>-->
      <el-table-column prop="resourceId" label="资源ID" width="100"></el-table-column>
      <el-table-column prop="resourceName" label="资源名称"></el-table-column>
      <el-table-column prop="templateName" label="监控模板"></el-table-column>
      <el-table-column prop="agentName" label="Agent 名称"></el-table-column>
      <el-table-column prop="agentIP" label="Agent IP"></el-table-column>
      <el-table-column label="操作"><!--功能-->
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="$router.push('/index/monitor/resource/data/'+scope.row['resourceId'])">
            查看
          </el-button>
          <el-button size="small" type="text" @click="setResourceDialog(scope.row)">修改</el-button>
          <el-button size="small" type="text" @click="delResourceConfirm(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :close-on-click-modal="false"
        :title="dialog.title"
        :visible.sync="dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <el-form label-width="100px" v-if="dialog.type === 1">
        <el-form-item label="资源组名">
          <el-input v-model="dialog.data.groupName"></el-input>
        </el-form-item>
      </el-form>
      <el-form label-width="100px" v-if="dialog.type === 2" v-loading="resourceLoading">
        <el-form-item label="资源名称">
          <el-input v-model="dialog.data.resourceName"></el-input>
        </el-form-item>
        <el-form-item label="监控模板">
          <el-select v-model="dialog.data.templateId" style="width: 100%" :disabled="setResourceDisabled">
            <el-option v-for="i in template.data" :label="i['templateName']" :value="i['templateId']"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Agent">
          <el-select v-model="dialog.data.agentId" style="width: 100%" :disabled="setResourceDisabled">
            <el-option v-for="i in agent.data"
                       :label="i['agentIP'] + ' | ' + i['agentName']"
                       :value="i['agentId']"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="dialogConfirm" :loading="dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import indexMonitorResourceApis from "@/apis/index-monitor/index-monitor-resource-apis";
import indexMonitorTemplateApis from "@/apis/index-monitor/index-monitor-template-apis";
import indexMonitorAgentApis from "@/apis/index-monitor/index-monitor-agent-apis";

export default {
  name: 'ItMonitorResource',
  data() {
    return {
      resourceGroup: {
        data: null,
        active: null,
        indexData: null,
      },
      resource: {
        data: null,
      },
      table: {
        height: null,
        loading: false,
      },
      dialog: {
        title: '',
        data: null,
        visible: false,
        loading: false,
        type: null, // 1 资源组 2 资源
      },
      template: {
        data: null,
      },
      agent: {
        data: null,
      },
      resourceLoading: false,
      setResourceDisabled: false,
    }
  },
  methods: {
    getResourceGroup() {
      this.resourceGroup.active = null;
      this.resourceGroup.data = null;
      indexMonitorResourceApis.getResourceGroup().then(data => {
        console.log(data);
        if (data === null) {
          return;
        }
        const rows = data['data'];
        this.resourceGroup.data = rows;
        if (rows.length !== 0) {
          this.resourceGroup.indexData = {};
          for (let i = 0; i < rows.length; i++) {
            this.resourceGroup.indexData[rows[i]['groupId']] = rows[i];
          }
          this.resourceGroup.active = rows[0]['groupId'].toString();
          this.getResource();
        }
      });
    },
    getResource() {
      this.$set(this.resource, 'data', null);
      indexMonitorResourceApis.getResource(this.resourceGroup.active).then(data => {
        if (data === null) {
          return;
        }
        this.$set(this.resource, 'data', data['data']);
      });
    },
    tableResize() {
      if (!this.$refs.body) {
        this.table.height = "0";
      } else {
        this.table.height = this.$refs.body.offsetHeight - 100 + "px";
      }
    },
    dialogConfirm() {
      switch (this.dialog.title) {
        case "新增资源组":
          this.addResourceGroup();
          break;
        case "修改资源组":
          this.setResourceGroup();
          break;
        case "新增资源":
          this.addResource();
          break;
        case "修改资源":
          this.setResource();
          break;
      }
    },
    addResourceGroupDialog() {
      this.dialog.title = '新增资源组';
      this.$set(this.dialog, 'data', {
        groupName: ''
      });
      this.dialog.type = 1;
      this.dialog.visible = true;
    },
    addResourceGroup() {
      if (this.dialog.data.groupName === '') {
        this.$message.error("请输入“资源组名”");
        return;
      }
      indexMonitorResourceApis.addResourceGroup(this.dialog.data.groupName).then(data => {
        if (data === null) {
          return;
        }
        this.getResourceGroup();
        this.$message.success("添加成功！");
        this.dialog.visible = false;
      });
    },
    setResourceGroupDialog() {
      if (this.resourceGroup.active === null) {
        return;
      }
      this.dialog.title = '修改资源组';
      if (!this.resourceGroup.indexData.hasOwnProperty(this.resourceGroup.active)) {
        this.$message.error("操作异常");
        return;
      }
      this.$set(this.dialog, 'data', JSON.parse(JSON.stringify(this.resourceGroup.indexData[this.resourceGroup.active])));
      this.dialog.type = 1;
      this.dialog.visible = true;
    },
    setResourceGroup() {
      if (this.dialog.data.groupId === null) {
        this.$message.error("操作异常");
        return;
      }
      if (this.dialog.data.groupName === '') {
        this.$message.error("请输入“资源组名”");
        return;
      }
      indexMonitorResourceApis.setResourceGroup(this.dialog.data.groupId, this.dialog.data.groupName).then(data => {
        if (data === null) {
          return;
        }
        this.getResourceGroup();
        this.$message.success("修改成功！");
        this.dialog.visible = false;
      });
    },
    delResourceGroupConfirm() {
      if (this.resourceGroup.active === null) {
        return;
      }
      if (!this.resourceGroup.indexData.hasOwnProperty(this.resourceGroup.active)) {
        this.$message.error("操作异常");
        return;
      }
      const groupName = this.resourceGroup.indexData[this.resourceGroup.active]['groupName'];
      this.$confirm("你确定要删除“" + groupName + "”吗？").then(() => {
        indexMonitorResourceApis.delResourceGroup(this.resourceGroup.active).then(data => {
          if (data === null) {
            return;
          }
          this.getResourceGroup();
          this.$message.success("删除成功！");
          this.dialog.visible = false;
        });
      }).catch(() => {
      });
    },
    getResourceSelectData() {
      const req = [
        indexMonitorTemplateApis.getTemplate(),
        indexMonitorAgentApis.getAgent(1, -1, {})
      ];
      this.resourceLoading = true;
      Promise.all(req).then(data => {
        console.log(data);
        // template
        if (data[0] !== null) {
          this.$set(this.template, 'data', data[0]['data']);
        }
        // agent
        if (data[1] !== null) {
          this.$set(this.agent, 'data', data[1]['data']['rows']);
        }
        this.resourceLoading = false;
      });
    },
    addResourceDialog() {
      if (this.resourceGroup.active === null) {
        return;
      }
      if (!this.resourceGroup.indexData.hasOwnProperty(this.resourceGroup.active)) {
        this.$message.error("操作异常");
        return;
      }
      this.getResourceSelectData();
      this.dialog.data = {
        templateId: null,
        agentId: null,
        resourceGroupId: parseInt(this.resourceGroup.active),
        resourceName: '',
      };
      this.setResourceDisabled = false;
      this.dialog.title = '新增资源';
      this.dialog.type = 2;
      this.dialog.visible = true;
    },
    resourceFormCheck(data) {
      if (data.resourceName === "") {
        this.$message.error("请输入“资源名称”");
        return false;
      }
      if (data.templateId === null) {
        this.$message.error("请选择“资源模板”");
        return false;
      }
      if (data.agentId === null) {
        this.$message.error("请选择“Agent”");
        return false;
      }
      if (data.resourceGroupId === null || typeof data.resourceGroupId !== "number") {
        this.$message.error("操作异常");
        return false;
      }
      return true;
    },
    addResource() {
      const data = this.dialog.data;
      if (!this.resourceFormCheck(data)) {
        return;
      }
      indexMonitorResourceApis.addResource(data['templateId'], data['agentId'], data['resourceGroupId'], data['resourceName']).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("新增成功！");
        this.getResource();
        this.dialog.visible = false;
      });
    },
    setResourceDialog(row) {
      if (this.resourceGroup.active === null) {
        return;
      }
      if (!this.resourceGroup.indexData.hasOwnProperty(this.resourceGroup.active)) {
        this.$message.error("操作异常");
        return;
      }
      this.getResourceSelectData();
      this.dialog.data = JSON.parse(JSON.stringify(row));
      this.setResourceDisabled = true;
      this.dialog.title = '修改资源';
      this.dialog.type = 2;
      this.dialog.visible = true;
    },
    setResource() {
      const data = this.dialog.data;
      if (!this.resourceFormCheck(data)) {
        return;
      }
      indexMonitorResourceApis.setResource(data.resourceId, data.resourceName).then(data => {
        if (data === null) {
          return null;
        }
        this.$message.success("修改成功！");
        this.getResource();
        this.dialog.visible = false;
      });
    },
    delResourceConfirm(row) {
      this.$confirm("确定要删除“" + row.resourceName + "”吗？").then(() => {
        indexMonitorResourceApis.delResource(row['resourceId']).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功！");
          this.getResource();
        })
      }).catch(() => {
      });
    }
  },
  mounted() {
    this.getResourceGroup();
    window.addEventListener('resize', this.tableResize);
    this.tableResize();
    // this.getResourceSelectData();
  }
}
</script>

<style scoped>

</style>