<template>
  <div class="login">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>登录</span>
      </div>
      <div>
        <el-form ref="form" :model="form" label-width="40px" :disabled="requestLoading">
          <el-form-item label="账号">
            <el-input @keydown.enter.native="login" v-model="form.user" placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input @keydown.enter.native="login" type="password" placeholder="请输入密码"
                      v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @keydown.enter.native="login" style="float: right" type="primary" @click="login"
                       :loading="requestLoading">登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

  </div>
</template>
<script>
import loginApis from "@/apis/login-apis";
export default {
  name: 'Login',
  data() {
    return {
      form: {
        user: "",
        password: ""
      },
      requestLoading: false
    };
  },
  methods: {
    login() {
      this.requestLoading = true;
      loginApis.login(
          this.form
      ).then(data => {
        const token = data['data']['token'];
        localStorage.setItem("token", token);
        this.requestLoading = false;
        this.$router.push('/index/itsm');
      });
      this.requestLoading = false;
    }
  },
  mounted() {
    window.vue = this;
  }
}
</script>

<style scoped>
.login {
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  min-width: 300px;
  max-width: 400px;
  width: 30%;

}
</style>