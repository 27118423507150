import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from "@/views/Login.vue";
import IndexITSM from "@/components/IndexITSM.vue";
import IndexMonitor from "@/components/IndexMonitor.vue";
import IndexResource from "@/components/IndexResource.vue";
import IndexResourceAttribute from "@/components/IndexResourceAttribute.vue";
import IndexResourceProfile from "@/components/IndexResourceProfile.vue";
import IndexResourceData from "@/components/IndexResourceData.vue";
import IndexResourceType from "@/components/IndexResourceType.vue";
import IndexResourceSearch from "@/components/IndexResourceSearch.vue";
import IndexResourceDataDetails from "@/components/IndexResourceDataDetails.vue";
import IndexMonitorProfile from "@/components/IndexMonitorProfile.vue";
import IndexMonitorResource from "@/components/IndexMonitorResource.vue";
import IndexMonitorTemplate from "@/components/IndexMonitorTemplate.vue";
import IndexMonitorAgent from "@/components/IndexMonitorAgent.vue";
import IndexMonitorAgentKey from "@/components/IndexMonitorAgentKey.vue";
import IndexMonitorTemplateItem from "@/components/IndexMonitorTemplateItem.vue";
import IndexMonitorResourceData from "@/components/IndexMonitorResourceData.vue";
import IndexITSMNew from "@/components/IndexITSMNew.vue";
import IndexITSMOrder from "@/components/IndexITSMOrder.vue";
import IndexITSMAdminOrder from "@/components/IndexITSMAdminOrder.vue";
import IndexITSMAdminTemplate from "@/components/IndexITSMAdminTemplate.vue";
import IndexITSMAdminTemplateField from "@/components/IndexITSMAdminTemplateField.vue";
import IndexITSMOrderDetails from "@/components/IndexITSMOrderDetails.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/index/itsm',
        name: 'IndexITSM',
        component: IndexITSM,
        children: [
          {
            path: '/index/itsm/new',
            name: 'IndexITSMNew',
            component: IndexITSMNew
          },
          {
            path: '/index/itsm/order/:type',
            name: 'IndexITSMOrder',
            component: IndexITSMOrder
          },
          {
            path: '/index/itsm/admin/order/:type',
            name: 'IndexITSMAdminOrder',
            component: IndexITSMAdminOrder
          },
          {
            path: '/index/itsm/order-details/:id',
            name: 'IndexITSMOrderDetails',
            component: IndexITSMOrderDetails
          },
          {
            path: '/index/itsm/admin/template',
            name: 'IndexITSMAdminTemplate',
            component: IndexITSMAdminTemplate
          },
          {
            path: '/index/itsm/admin/template/field/:id',
            name: 'IndexITSMAdminTemplateField',
            component: IndexITSMAdminTemplateField
          }
        ],
      },
      {
        path: '/index/monitor',
        name: 'IndexMonitor',
        component: IndexMonitor,
        children: [
          {
            path: '/index/monitor/profile',
            name: 'IndexMonitorProfile',
            component: IndexMonitorProfile
          },
          {
            path: '/index/monitor/resource',
            name: 'IndexMonitorResource',
            component: IndexMonitorResource
          },
          {
            path: '/index/monitor/resource/data/:id',
            name: 'IndexMonitorResourceData',
            component: IndexMonitorResourceData
          },
          {
            path: '/index/monitor/template',
            name: 'IndexMonitorTemplate',
            component: IndexMonitorTemplate
          },
          {
            path: '/index/monitor/template/:id',
            name: 'IndexMonitorTemplateItem',
            component: IndexMonitorTemplateItem
          },
          {
            path: '/index/monitor/agent',
            name: 'IndexMonitorAgent',
            component: IndexMonitorAgent
          },
          {
            path: '/index/monitor/agent-key',
            name: 'IndexMonitorAgentKey',
            component: IndexMonitorAgentKey
          }
        ]
      },
      {
        path: '/index/resource',
        name: 'IndexResource',
        component: IndexResource,
        children: [
          {
            path: '/index/resource/profile',
            name: 'IndexResourceProfile',
            component: IndexResourceProfile
          },
          {
            path: '/index/resource/search',
            name: 'IndexResourceSearch',
            component: IndexResourceSearch
          },
          {
            path: '/index/resource/data',
            name: 'IndexResourceData',
            component: IndexResourceData
          },
          {
            path: '/index/resource/data/details/:id',
            name: 'IndexResourceDataDetails',
            component: IndexResourceDataDetails
          },
          {
            path: '/index/resource/attribute',
            name: 'IndexResourceAttribute',
            component: IndexResourceAttribute
          },
          {
            path: '/index/resource/type',
            name: 'IndexResourceType',
            component: IndexResourceType
          },
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    name: 'index_default',
    path: '/index/*',
    redirect: '/index/itsm'
  },
  {
    name: 'default',
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  routes
})

export default router
