import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import axios from "axios";
import http from "@/request/api";
import store from "@/store";
import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;

Vue.use(echarts);

Vue.config.productionTip = false

Vue.prototype.$http = http

Vue.use(ElementUI);

new Vue({
  store,
  router, render: h => h(App)
}).$mount('#app')
