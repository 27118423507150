<template>
  <div ref="body">
    <table style="width: 100%; height: 40px;">
      <tbody style="width: 100%">
      <tr>
        <td>
          <el-button type="success" size="small" @click="addAttributeDialog">新增属性</el-button>
          <el-button size="small" @click="refreshAttribute">刷新属性</el-button>
        </td>
        <td style="text-align: right">
          <el-button type="success" size="small" @click="addGroupDialog">新增分组</el-button>
          <el-button size="small" @click="setGroupDialog">修改当前分组</el-button>
          <el-button type="danger" size="small" @click="delGroup">删除当前分组</el-button>
          <el-button size="small" @click="getGroups">刷新分组</el-button>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <el-tabs v-model="group.active" @tab-click="handleGroupClick" style="height: 50px;">
        <el-tab-pane v-for="v in group.groups" :label="v.groupName" :name="v.groupId.toString()">
        </el-tab-pane>
      </el-tabs>
      <el-table
          ref="table"
          :height="attribute.table.height"
          :data="attributeTableData"
          v-loading="attribute.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="attributeName" label="属性"></el-table-column>
        <el-table-column prop="displayName" label="属性名称"></el-table-column>
        <el-table-column prop="attributeTypeStr" label="属性类型"></el-table-column>
        <el-table-column prop="valueTypeStr" label="值类型"></el-table-column>
        <el-table-column prop="options" label="选项"></el-table-column>
        <el-table-column prop="defaultValue" label="默认值"></el-table-column>
        <el-table-column label="操作"><!--功能-->
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="setAttributeDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delAttribute(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
          background
          @size-change="attributeTableSizeChange"
          @current-change="attributeTableCurrentChange"
          :current-page="attribute.table.pageCurrent"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="attribute.table.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 10px; height: 36px; float: right;"
          :total="attribute.table.pageTotal">
      </el-pagination>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="group.dialog.title"
        :visible.sync="group.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-input v-model="group.dialog.input"></el-input>
      </span>
      <span slot="footer">
        <el-button @click="group.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="groupDialogEvent" :loading="group.dialog.loading">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :close-on-click-modal="false"
        :title="attribute.dialog.title"
        :visible.sync="attribute.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-form label-width="100px">
          <el-form-item label="属性">
            <el-input style="width: 95%;" v-model="attribute.dialog.input.attribute" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="属性名称">
            <el-input style="width: 95%;" v-model="attribute.dialog.input.displayName"
                      placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="属性类型">
              <el-select style="width: 95%;" v-model="attribute.dialog.input.attributeType" placeholder="请选择">
                <el-option
                    v-for="(item, key) in attribute.attributeTypeMap"
                    :key="key"
                    :label="item"
                    :value="key">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="值类型" v-show="attribute.dialog.input.attributeType === '0'">
              <el-select style="width: 95%;" v-model="attribute.dialog.input.attributeValueType" placeholder="请选择">
                <el-option
                    v-for="(item, key) in attribute.attributeValueMap"
                    :key="key"
                    :label="item"
                    :value="key">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="选项"
                        v-show="attribute.dialog.input.attributeType === '2'">
            <el-input style="width: 95%;" v-model="attribute.dialog.input.options"
                      placeholder="请输入（用英文逗号,分隔）"></el-input>
          </el-form-item>
          <el-form-item label="默认值"
                        v-show="attribute.dialog.input.attributeType === '0'||attribute.dialog.input.attributeType === '2'">
            <el-input style="width: 95%;" v-model="attribute.dialog.input.defaultValue"
                      placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="attribute.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="attributeDialogForm" :loading="attribute.dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import indexResourceAttributeApis from '@/apis/index-resource/index-resource-attribute-apis';

export default {
  name: 'IndexResourceAttribute',
  data() {
    return {
      group: {
        dialog: {
          title: "",
          visible: false,
          input: "",
          loading: false,
        },
        groups: [], // groupId, groupName
        groupsIndexById: {},
        active: null
      },
      attribute: {
        table: {
          height: null,
          pageCurrent: 1,
          pageSize: 20,
          pageTotal: 0,
          loading: false,
        },
        dialog: {
          title: "",
          visible: false,
          input: {
            attributeId: -1,
            attribute: "",
            displayName: "",
            attributeType: "",
            attributeTypeInt: -1,
            attributeValueType: "",
            attributeValueTypeInt: -1,
            defaultValue: "",
            options: ""
          },
          loading: false,
        },
        attributeTypeMap: {
          0: '普通',
          1: '表格',
          2: '下拉选项'
        },
        attributeValueMap: {
          0: '文本',
          1: '数字'
        }
      }
    };
  },
  methods: {
    getGroups() {
      indexResourceAttributeApis.getGroups().then((data) => {
        if (data === null) {
          return;
        }
        this.group.groups = data.data;
        if (this.group.groups.length !== 0) {
          this.group.active = this.group.groups[0].groupId.toString();
          for (let i = 0; i < this.group.groups.length; i++) {
            this.group.groupsIndexById[this.group.groups[i]['groupId']] = this.group.groups[i]['groupName'];
          }
        }
        this.getAttribute();
      });
    },
    handleGroupClick() {
      this.getAttribute();
    },
    groupDialogEvent() {
      switch (this.group.dialog.title) {
        case "新增":
          this.addGroupApi();
          break;
        case "修改":
          this.setGroup();
          break;
      }
    },

    addGroupDialog() {
      this.group.dialog.title = "新增";
      this.group.dialog.input = "";
      this.group.dialog.visible = true;
    },
    addGroupApi() {
      if (this.group.dialog.input === "") {
        this.$message.error("请输入组名");
        return;
      }
      this.group.dialog.loading = true;
      indexResourceAttributeApis.addGroups(this.group.dialog.input).then((data) => {
        if (data === null) {
          return;
        }
        this.$message.success("添加成功");
        this.group.dialog.visible = false;
        this.getGroups();
      });
      this.group.dialog.loading = false;
    },
    setGroupDialog() {
      this.group.dialog.title = "修改";
      this.group.dialog.input = this.group.groupsIndexById[parseInt(this.group.active)];
      this.group.dialog.visible = true;
    },
    setGroup() {
      if (this.group.dialog.input === "") {
        this.$message.error("请输入组名");
        return;
      }
      this.group.dialog.loading = true;
      indexResourceAttributeApis.setGroup(
          parseInt(this.group.active),
          this.group.dialog.input
      ).then((data) => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功");
        this.group.dialog.visible = false;
        this.getGroups();
      });

      this.group.dialog.loading = false;
    },
    delGroup() {
      this.$confirm("确认要删除分组“" + this.group.groupsIndexById[parseInt(this.group.active)] + "”吗？").then(() => {
        indexResourceAttributeApis.delGroup(this.group.active).then((data) => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功");
          this.getGroups();
        });
      }).catch(() => {
      });
    },
    // attribute
    attributeTableResize() {
      if (!this.$refs.body) {
        this.attribute.table.height = "0";
      } else {
        this.attribute.table.height = this.$refs.body.offsetHeight - 150 + "px";
      }
    },
    attributeTableSizeChange(size) {
      this.attribute.table.pageSize = size;
      this.getAttribute();
    },
    attributeTableCurrentChange(current) {
      this.attribute.table.pageCurrent = current;
      this.getAttribute();
    },
    refreshAttribute() {
      this.attribute.table.pageCurrent = 1;
      this.attribute.table.pageSize = 20;
      this.getAttribute();
      this.$refs.table.$el.children[2].scrollTop = 0;
      // console.log(this.$refs.table.$el.children[2]);
    },
    getAttribute() {
      this.attribute.table.loading = true;
      indexResourceAttributeApis.getAttribute(
          this.attribute.table.pageCurrent,
          this.attribute.table.pageSize,
          parseInt(this.group.active)
      ).then((res) => {
        this.attribute.table.loading = false;
        const data = res['data'];
        this.$set(this.attribute.table, 'data', data['rows']);
        this.attribute.table.pageTotal = data['totalSize'];
      });
    },
    addAttributeDialog() {
      this.attribute.dialog.title = '新增';
      this.attribute.dialog.visible = true;
      for (const inputKey in this.attribute.dialog.input) {
        this.attribute.dialog.input[inputKey] = "";
      }
    },
    attributeDialogForm() {
      if (this.attribute.dialog.title === '新增') {
        this.addAttribute();
      } else if (this.attribute.dialog.title === '修改') {
        this.setAttribute();
      }
    },
    attributeDialogFormCheck() {
      if (this.attribute.dialog.input.attribute === "") {
        this.$message.error("请输入“属性”");
        return;
      }
      if (this.attribute.dialog.input.displayName === "") {
        this.$message.error("请输入“属性名称”");
        return;
      }
      if (this.attribute.dialog.input.attributeType === "") {
        this.$message.error("请选择“属性类型”");
        return;
      }
      this.attribute.dialog.input.attributeTypeInt = parseInt(this.attribute.dialog.input.attributeType);
      if (this.attribute.dialog.input.attributeTypeInt < 0 || this.attribute.dialog.input.attributeTypeInt > 3) {
        this.$message.error("“属性类型”错误！");
        return;
      }
      if (this.attribute.dialog.input.attributeTypeInt === 0) {
        if (this.attribute.dialog.input.attributeValueType === "") {
          this.$message.error("请选择“值类型”");
          return;
        }
        this.attribute.dialog.input.attributeValueTypeInt = parseInt(this.attribute.dialog.input.attributeValueType);
        if (this.attribute.dialog.input.attributeValueTypeInt < 0 || this.attribute.dialog.input.attributeValueTypeInt > 1) {
          this.$message.error("“值类型”错误！");
          return;
        }
        this.attribute.dialog.input.options = "";
      }
      if (this.attribute.dialog.input.attributeTypeInt === 1) {
        this.attribute.dialog.input.attributeValueType = "0";
        this.attribute.dialog.input.attributeValueTypeInt = 0;
      }
      if (this.attribute.dialog.input.attributeTypeInt === 2) {
        if (this.attribute.dialog.input.options === "") {
          this.$message.error("请输入“选项”");
          return;
        }
        if (this.attribute.dialog.input.defaultValue !== "") {
          const arr = this.attribute.dialog.input.options.split(',');
          let exists = false;
          for (const data in arr) {
            if (arr[data] === this.attribute.dialog.input.defaultValue) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            this.$message.error("“默认值”错误！");
            return;
          }
        }
        this.attribute.dialog.input.attributeValueType = "0";
        this.attribute.dialog.input.attributeValueTypeInt = 0;
      }
      return true;
    },
    addAttribute() {
      const success = this.attributeDialogFormCheck();
      if (!success) {
        return;
      }
      const input = this.attribute.dialog.input;
      indexResourceAttributeApis.addAttribute(
          parseInt(this.group.active), input.attribute, input.displayName, input.attributeTypeInt, input.attributeValueTypeInt, input.defaultValue, input.options
      ).then((data) => {
        if (data === null) {
          return;
        }
        this.$message.success("添加成功");
        this.attribute.dialog.visible = false;
        this.refreshAttribute();
      });
    },
    setAttributeDialog(row) {
      console.log(row);
      this.attribute.dialog.title = '修改';

      const input = this.attribute.dialog.input;
      input.attribute = row.attributeName;
      input.displayName = row.displayName;
      input.attributeType = row.attributeType.toString();
      input.attributeValueType = row.valueType.toString();
      input.options = row.options;
      input.attributeId = row.attributeId;
      input.defaultValue = row.defaultValue;

      this.attribute.dialog.visible = true;
    },
    setAttribute() {
      const success = this.attributeDialogFormCheck();
      if (!success) {
        return;
      }
      console.log(success, this.attribute.dialog.input);
      const input = this.attribute.dialog.input;
      indexResourceAttributeApis.setAttribute(
          input.attributeId, parseInt(this.group.active), input.attribute, input.displayName, input.attributeTypeInt, input.attributeValueTypeInt, input.defaultValue, input.options
      ).then((data) => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功");
        this.attribute.dialog.visible = false;
        this.refreshAttribute();
      });
    },
    delAttribute(row) {
      this.$confirm("确认要删除“" + row.displayName + "”吗？").then(() => {
        const id = row.attributeId;
        indexResourceAttributeApis.delAttribute(id).then((data) => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功");
          this.refreshAttribute();
        });
      }).catch(() => {
      });
    }

  },
  mounted() {
    window.vueResourceAttribute = this;
    window.addEventListener('resize', this.attributeTableResize);
    this.attributeTableResize();
    this.getGroups();
    // this.attributeDialog();
  },
  computed: {
    attributeTableData() {
      const data = this.attribute.table.data;
      if (!data) {
        return null;
      }

      const attributeTypeKey = Object.keys(this.attribute.attributeTypeMap);
      const attributeValueKey = Object.keys(this.attribute.attributeValueMap);

      for (let i = 0; i < data.length; i++) {
        data[i]['valueTypeStr'] = '--';
        if (attributeTypeKey.includes(data[i]['attributeType'].toString())) {
          data[i]['attributeTypeStr'] = this.attribute.attributeTypeMap[data[i]['attributeType']];
          if (data[i]['attributeType'] === 0) {
            if (attributeValueKey.includes(data[i]['valueType'].toString())) {
              data[i]['valueTypeStr'] = this.attribute.attributeValueMap[data[i]['valueType']];
            }
          }
        }
      }
      return data;
    }
  }
}
</script>

<style scoped>

</style>