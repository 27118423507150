<template>
  <div>
    search
  </div>
</template>
<script>

export default {
  name: 'IndexResourceSearch',
  data() {
    return {};
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>