<template>
  <div>资源概览</div>
</template>
<script>

export default {
  name: 'IndexMonitorProfile'
}
</script>

<style scoped>

</style>