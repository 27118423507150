import api from "@/request/api";

export default {
  getTemplate: () => {
    return api.post('/itsm/template/get');
  },
  getTemplateById: (templateId) => {
    return api.post('/itsm/template/get-by-id/' + templateId);
  },
  addTemplate: (name, templateUser) => {
    return api.post('/itsm/admin/template/add', {
      name, templateUser
    });
  },
  setTemplate: (templateId, name, templateUser) => {
    return api.post('/itsm/admin/template/set', {
      templateId, name, templateUser
    });
  },
  delTemplate: (templateId) => {
    return api.post('/itsm/admin/template/del/' + templateId);
  },
  getOptUser: () => {
    return api.post('/itsm/admin/template/get-opt-user');
  },
  getTemplateField: (templateId) => {
    return api.post('/itsm/template/field/get/' + templateId);
  },
  addTemplateField: (title, templateId, required, fieldType, options) => {
    return api.post('/itsm/admin/template/field/add', {
      title, templateId, required, fieldType, options
    });
  },
  delTemplateField: (fieldId) => {
    return api.post('/itsm/admin/template/field/del/' + fieldId);
  },
  setTemplateField: (fieldId, title, templateId, required, fieldType, options) => {
    return api.post('/itsm/admin/template/field/set', {
      fieldId, title, templateId, required, fieldType, options
    });
  }
};