import api from "@/request/api";

export default {
  getGroups: () => {
    return api.post('/resource/group/get');
  },
  addGroups: (groupName) => {
    return api.post('/resource/group/add', {
      groupName
    });
  },
  setGroup: (groupId, groupName) => {
    return api.post('/resource/group/set', {
      groupId, groupName
    });
  },
  delGroup: (groupId) => {
    return api.post('/resource/group/del/' + groupId);
  },
  getAttribute: (pageNo, pageSize, groupId) => {
    return api.post('/resource/attribute/get', {
      pageNo, pageSize, groupId
    });
  },
  addAttribute(groupId, attributeName, displayName, attributeType, valueType, defaultValue, options) {
    return api.post('/resource/attribute/add',
      {
        groupId, attributeName, displayName, attributeType, valueType, defaultValue, options
      }
    );
  },
  setAttribute(attributeId, groupId, attributeName, displayName, attributeType, valueType, defaultValue, options) {
    return api.post('/resource/attribute/set',
      {
        attributeId, groupId, attributeName, displayName, attributeType, valueType, defaultValue, options
      }
    );
  },
  delAttribute(attributeId) {
    return api.post('/resource/attribute/del/' + attributeId);
  }
};