<template>
  <div ref="body">
    <div style="height: 40px">
      <el-button size="small" type="success" @click="addTemplateDialog">新增</el-button>
      <el-button size="small" type="primary" @click="getTemplate">刷新</el-button>
    </div>
    <div>
      <el-table
          ref="table"
          :height="template.table.height"
          :data="template.table.data"
          v-loading="template.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="模板名称"></el-table-column>
        <el-table-column prop="optUserName" label="运维操作人"></el-table-column>

        <el-table-column label="操作"><!-- 功能 -->
          <template slot-scope="scope">
            <el-button size="small" type="text"
                       @click="$router.push('/index/itsm/admin/template/field/'+scope.row['templateId'])">查看
            </el-button>
            <el-button size="small" type="text" @click="setTemplateDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delTemplate(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <!-- template dialog -->
    <el-dialog
        :close-on-click-modal="false"
        :title="template.dialog.title"
        :visible.sync="template.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-input v-model="template.dialog.templateName" placeholder="模板名称"></el-input>
        <el-select v-loading="!getOptFinished" v-model="template.dialog.templateUserId" placeholder="运维操作人"
                   style="width: 100%; margin-top: 20px;">
          <el-option v-for="i in template.dialog.optUser" :label="i['displayName']" :value="i['userId']"></el-option>
        </el-select>
      </span>
      <span slot="footer">
        <el-button @click="template.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="templateDialogConfirm" :loading="template.dialog.loading">确定</el-button>
      </span>
    </el-dialog>

    <!-- item dialog -->
    <!--    <el-dialog
            :close-on-click-modal="false"
            :title="itemDialog.title"
            :visible.sync="itemDialog.visible"
            fullscreen
            :destroy-on-close="true">
          <div :style="'overflow-y: auto; height: '+ itemDialog.dialogHeight">
            <el-table
                :height="itemDialog.table.height"
                :data="itemDialog.table.data"
                v-loading="itemDialog.table.loading">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column prop="" label="序号"></el-table-column>
            </el-table>
          </div>
          <span slot="footer">
            <el-button @click="itemDialog.visible = false">关闭</el-button>
            &lt;!&ndash;<el-button type="primary" @click="templateDialogConfirm" :loading="itemDialog.loading">确定</el-button>&ndash;&gt;
          </span>
        </el-dialog>-->

  </div>
</template>
<script>
import indexItsmAdminTemplateApis from "@/apis/index-itsm/index-itsm-admin-template-apis";

export default {
  name: 'IndexITSMAdminTemplate',
  data() {
    return {
      template: {
        table: {
          height: null,
          loading: false,
          data: null
        },
        dialog: {
          title: '',
          visible: false,
          loading: false,
          templateName: '',
          templateUserId: null,
          rowInd: null,
          optUser: [],
        }
      },
      getOptFinished: false,
      itemDialog: {
        title: '',
        visible: false,
        loading: false,
        data: null,
        dialogHeight: null,
        table: {
          height: null,
          loading: false,
          data: null,
        }
      }
    }
  },
  methods: {
    getTemplate() {
      indexItsmAdminTemplateApis.getTemplate().then(data => {
        if (data === null) {
          return;
        }
        console.log(data);
        this.$set(this.template.table, 'data', data['data']);
      });
    },
    templateDialog(title) {
      this.template.dialog.title = title;
      this.template.dialog.visible = true;
    },
    templateDialogConfirm() {
      switch (this.template.dialog.title) {
        case "新增":
          this.addTemplate();
          break;
        case "修改":
          this.setTemplate();
          break;
      }
    },
    addTemplateDialog() {
      this.getOptFinished = false;
      indexItsmAdminTemplateApis.getOptUser().then(data => {
        if (data === null) {
          return;
        }
        this.$set(this.template.dialog, 'optUser', data['data']);
        this.getOptFinished = true;
      });
      this.template.dialog.templateName = '';
      this.template.dialog.templateUserId = null;
      this.templateDialog('新增');
    },
    addTemplate() {
      if (this.template.dialog.templateName === '') {
        this.$message.error("“模板名称”不能为空！");
        return;
      }
      if (this.template.dialog.templateUserId === null) {
        this.$message.error("请选择“运维操作人”！");
        return;
      }
      indexItsmAdminTemplateApis.addTemplate(this.template.dialog.templateName, this.template.dialog.templateUserId).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("新增成功！");
        this.getTemplate();
        this.template.dialog.visible = false;
      })
    },
    setTemplateDialog(row) {
      this.getOptFinished = false;
      indexItsmAdminTemplateApis.getOptUser().then(data => {
        if (data === null) {
          return;
        }
        this.$set(this.template.dialog, 'optUser', data['data']);
        this.getOptFinished = true;
      });
      this.template.dialog.templateName = row['name'];
      this.template.dialog.templateUserId = row['templateUser'];
      this.template.dialog.rowInd = row;
      this.templateDialog("修改");
    },
    setTemplate() {
      if (this.template.dialog.templateName === '') {
        this.$message.error("“模板名称”不能为空！");
        return;
      }
      if (this.template.dialog.templateUserId === null) {
        this.$message.error("请选择“运维操作人”！");
        return;
      }
      indexItsmAdminTemplateApis.setTemplate(this.template.dialog.rowInd['templateId'], this.template.dialog.templateName, this.template.dialog.templateUserId).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功！");
        this.getTemplate();
        this.template.dialog.visible = false;
      });
    },
    delTemplate(row) {
      this.$confirm("你确定要删除“" + row['name'] + "”吗？").then(() => {
        indexItsmAdminTemplateApis.delTemplate(row['templateId']).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功！");
          this.getTemplate();
          this.template.dialog.visible = false;
        });
      }).catch(() => {
      });
    },
    tableResize() {
      if (!this.$refs.body) {
        this.template.table.height = "0";
        this.itemDialog.dialogHeight = "0";
      } else {
        this.template.table.height = this.$refs.body.offsetHeight - 100 + "px";
        this.itemDialog.dialogHeight = this.$refs.body.offsetHeight - 100 + "px";
      }
    },
    // fieldDialog(row) {
    //   const templateId = row['templateId'];
    //   this.itemDialog.title = row['name'];
    //   this.itemDialog.visible = true;
    // },
  },
  mounted() {
    this.getTemplate();
    window.addEventListener('resize', this.tableResize);
    this.$nextTick(() => {
      this.tableResize();
    });
  }
}
</script>

<style scoped>

</style>