import api from "@/request/api";

export default {
  // agent key
  getAgentKey: () => {
    return api.post('/monitor/agent/key/get');
  },
  addAgentKey: (remark) => {
    return api.post('/monitor/agent/key/gen', {
      remark
    });
  },
  delAgentKey: (id) => {
    return api.post('/monitor/agent/key/del/' + id);
  },
  setAgentKeyRemark: (id, remark) => {
    return api.post('/monitor/agent/key/set', {
      id, remark
    });
  },

  // agent
  getAgent: (pageNo = 1, pageSize = 20, filter = null) => {
    return api.post('/monitor/agent/get', {
      pageNo, pageSize, filter
    });
  },
  addAgent: (agentName, agentIP, agentKeyId) => {
    return api.post('/monitor/agent/add', {
      agentName, agentIP, agentKeyId
    });
  },
  setAgent: (agentId, agentName, agentIP, agentKeyId) => {
    return api.post('/monitor/agent/set', {
      agentId, agentName, agentIP, agentKeyId
    });
  },
  delAgent: (agentId) => {
    return api.post('/monitor/agent/del/' + agentId);
  }
};