<template>
  <div class="index-body">
    <div
        style="width: 200px; height: 100%; float: left; overflow: hidden; border: 1px solid #e6e6e6; border-radius: 5px;">
      <div style="height: 100%; width: 218px; overflow-y: scroll; padding-right: 17px; margin-right: -17px;">
        <el-menu
            style="height: 100%"
            :default-active="navActive"
            router
            :default-openeds="navMenu.defaultOpen"
        >
          <!--<el-menu-item index="/index/resource/profile">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>资源概览</span>
            </template>
          </el-menu-item>
          <el-menu-item index="/index/resource/search">
            <template slot="title">
              <i class="el-icon-search"></i>
              <span>资源搜索</span>
            </template>
          </el-menu-item>-->
          <el-menu-item index="/index/resource/data">
            <template slot="title">
              <i class="el-icon-coin"></i>
              <span>资源维护</span>
            </template>
          </el-menu-item>
          <el-submenu index="setting">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>资源设置</span>
            </template>
            <el-menu-item index="/index/resource/attribute">资源属性</el-menu-item>
            <el-menu-item index="/index/resource/type">资源类型</el-menu-item>
          </el-submenu>
        </el-menu>

      </div>

    </div>
    <div class="main">
      <router-view style="padding: 5px; height: calc(100% - 10px); width: calc(100% - 10px); overflow-y: auto"/>
    </div>
  </div>
</template>
<script>

export default {
  name: 'IndexResource',
  data() {
    return {
      navMenu: {
        defaultOpen: []
      }
    };
  },
  methods: {
    menuDefaultOpen() {
      const pathArr = this.$route.path.split('/');
      if (pathArr[pathArr.length - 1] === 'attribute' || pathArr[pathArr.length - 1] === 'type') {
        this.navMenu.defaultOpen.push('setting');
      }
    },
    checkPath() {
      if (this.$route.path === '/index/resource') {
        this.$router.replace('/index/resource/data');
      }
    }
  },
  mounted() {
    this.checkPath();
  },
  watch: {
    '$route.path': 'checkPath'
  },
  computed: {
    navActive() {
      const path = this.$route.path;
      const pathArr = path.split('/');
      return pathArr.slice(0, 4).join('/');
    }
  }
}
</script>

<style scoped>
.main {
  float: right;
  height: 100%;
  width: calc(100% - 220px);
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}
</style>