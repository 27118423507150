<template>
  <div>
    <div style="width: 100%; height: 40px;">
      <span
          style="font-weight: bold; margin-left: 5px; line-height: 40px; color: #606266; font-size: 14px; margin-right: 5px; user-select: none; cursor: default">工单详情</span>
      <el-button type="text" @click="logDialogClick">流转情况</el-button>
    </div>
    <div v-if="user.data !== null && template.data !== null && field.data !== null && order.data !== null"
         style="width: calc(100% - 10px); height: calc(100% - 90px); padding: 5px; overflow-y: auto">
      <el-form v-loading="field.loading" label-width="100px" v-if="!field.loading">
        <el-form-item label="流程">
          <p style="color: #606266; font-size: 14px;">{{ template.data.name }}</p>
        </el-form-item>
        <el-form-item label="申请人">
          <p style="color: #606266; font-size: 14px;">{{ order.data['userName'] }}</p>
        </el-form-item>
        <el-form-item label="运维人员">
          <p style="color: #606266; font-size: 14px;">{{ template.data['optUserName'] }}</p>
        </el-form-item>
        <el-form-item label="当前节点">
          <p style="color: #606266; font-size: 14px;">{{
              orderStatusStr[order.data['orderStatus']] + ((order.data['orderStatus'] !== 3) ? (" - " + order.data['currentUserName']) : "")
            }}</p>
        </el-form-item>
        <el-form-item label="名称" required>
          <el-input :disabled="true"
                    style="width: 100%" v-model="order.data.orderName"></el-input>
        </el-form-item>
        <div v-for="f in field.data">
          <el-form-item v-if="f.fieldType === 0" :label="f.title" :required="f.required === 1">
            <el-input :readonly="order.data.orderStatus !== 2 || user.data['userId'] !== order.data['currentUserId']"
                      style="width: 100%" v-model="field.input[f.fieldId]"></el-input>
          </el-form-item>
          <el-form-item v-if="f.fieldType === 1" :label="f.title" :required="f.required === 1">
            <el-select style="width: 100%" :value="field.input[f.fieldId]"
                       v-if="order.data.orderStatus !== 2 || user.data['userId'] !== order.data['currentUserId']"
                       disabled>
              <el-option v-for="o in f.options.split(',')" :label="o" :value="o"></el-option>
            </el-select>
            <el-select style="width: 100%" v-model="field.input[f.fieldId]"
                       v-if="order.data.orderStatus === 2 && user.data['userId'] === order.data['currentUserId']">
              <el-option v-for="o in f.options.split(',')" :label="o" :value="o"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="f.fieldType === 2" :label="f.title" :required="f.required === 1">
            <el-input style="width: 100%" :autosize="{minRows: 5, maxRows: 5}"
                      resize="vertical"
                      :readonly="order.data.orderStatus !== 2 || user.data['userId'] !== order.data['currentUserId']"
                      v-model="field.input[f.fieldId]" type="textarea" rows="5"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 处理 -->
      <div v-if="order.data.orderStatus !== 2">
        <el-form label-width="100px">
          <el-form-item label="处理结果">
            <el-input style="width: 100%" :autosize="{minRows: 5, maxRows: 5}"
                      v-if="order.data.orderStatus === 0 && user.data['userId'] === order.data['currentUserId']"
                      :readonly="order.data.orderStatus !== 0 || user.data['userId'] !== order.data['currentUserId']"
                      resize="vertical" type="textarea" rows="5" v-model="input.pass.reply"></el-input>
            <el-input v-else style="width: 100%" :autosize="{minRows: 5, maxRows: 5}" :readonly="true"
                      resize="vertical" type="textarea" rows="5" v-model="order.data['reply']"></el-input>
          </el-form-item>
          <el-form-item label="备注"
                        v-if="order.data.orderStatus === 0 && user.data['userId'] === order.data['currentUserId']">
            <el-input style="width: 100%" :autosize="{minRows: 5, maxRows: 5}"
                      :readonly="order.data.orderStatus !== 0 || user.data['userId'] !== order.data['currentUserId']"
                      resize="vertical" type="textarea" rows="5" v-model="input.remark"></el-input>
          </el-form-item>
          <el-form-item label="评价"
                        v-if="(order.data.orderStatus === 1 && user.data['userId'] === order.data['currentUserId']) || order.data.orderStatus === 3">
            <div v-if="order.data.orderStatus === 1 && user.data['userId'] === order.data['currentUserId']">
              <el-input style="width: 100%" :autosize="{minRows: 5, maxRows: 5}"
                        resize="vertical" type="textarea" rows="5" v-model="input.comment.comment"></el-input>

              <el-rate style="margin-top: 10px;" v-model="input.comment.evaluate"></el-rate>
            </div>
            <div v-else-if="order.data.orderStatus === 3">
              <!-- readonly -->
              <el-input style="width: 100%" :autosize="{minRows: 5, maxRows: 5}" readonly
                        resize="vertical" type="textarea" rows="5" v-model="order.data.comment"></el-input>

              <el-rate style="margin-top: 10px;" v-model="order.data.evaluate" disabled></el-rate>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="user.data !== null && template.data !== null && field.data !== null" style="width: 100%; height: 40px; ">
      <div style="float: right">
        <el-button v-if="order.data.orderStatus === 0 && user.data['userId'] === order.data['currentUserId']"
                   size="small" type="success" @click="passOrder">通过
        </el-button>
        <el-button v-if="order.data.orderStatus === 0 && user.data['userId'] === order.data['currentUserId']"
                   size="small" type="danger" @click="returnOrder">退回
        </el-button>
        <el-button v-if="order.data.orderStatus === 2 && user.data['userId'] === order.data['currentUserId']"
                   size="small" type="success" @click="resubmit">提交
        </el-button>
        <el-button v-if="order.data.orderStatus === 1 && user.data['userId'] === order.data['currentUserId']"
                   size="small" type="success" @click="comment">提交评价
        </el-button>
        <el-button size="small" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="logDialog.title"
        :visible.sync="logDialog.visible"
        width="60%"
        :destroy-on-close="true">
      <span>
        <el-table :data="logDialog.data">
          <el-table-column prop="time" label="操作时间" width="200"></el-table-column>
          <el-table-column prop="userName" label="用户" width="150"></el-table-column>
          <el-table-column prop="log" label="日志"></el-table-column>
        </el-table>
      </span>
      <span slot="footer">
        <el-button @click="logDialog.visible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import indexItsmOrderDetailsApis from "@/apis/index-itsm/index-itsm-order-details-apis";
import indexItsmAdminTemplateApis from "@/apis/index-itsm/index-itsm-admin-template-apis";
import indexGlobalApis from "@/apis/index-global-apis";

export default {
  name: 'IndexITSMOrderDetails',
  data() {
    return {
      orderStatusStr: {
        0: '进行中',
        1: '待评价',
        2: '已退回',
        3: '已结束',
      },
      user: {
        data: null,
      },
      order: {
        data: null,
        id: null,
      },
      template: {
        templateId: null,
        data: null,
      },
      field: {
        loading: false,
        data: null,
        input: {},
        fieldIdIndex: {},
      },
      input: {
        remark: '',
        pass: {
          reply: '',
        },
        comment: {
          evaluate: 0,
          comment: ''
        }
      },
      logDialog: {
        title: '',
        visible: false,
        loading: false,
        data: null,
      },
    };
  },
  methods: {
    init() {
      console.log('init');
      Object.assign(this.$data, this.$options.data.call(this));

      this.order.id = Number(this.$route.params['id']);
      this.getUser();
      this.getOrder();
    },
    getUser() {
      indexGlobalApis.userInfo().then(data => {
        if (data === null) {
          return;
        }
        this.user.data = data['data'];
      });
    },
    getOrder() {
      indexItsmOrderDetailsApis.getOrderById(this.order.id).then(data => {
        if (data === null) {
          return;
        }
        if (data['data'] === null) {
          this.$message.error("找不到该工单！");
          return;
        }
        this.order.data = data['data'];
        try {
          this.$set(this.order.data, 'dataObj', JSON.parse(this.order.data['data']));
        } catch (e) {
          console.log(e);
        }
        for (let i = 0; i < this.order.data['dataObj'].length; i++) {
          this.$set(this.field.input, this.order.data['dataObj'][i]['fieldId'], this.order.data['dataObj'][i]['data']);
        }
        console.log(this.order.data);
        this.template.templateId = data['data']['templateId'];
      });
    },
    getTemplate() {
      indexItsmAdminTemplateApis.getTemplateById(this.template.templateId).then(data => {
        if (data === null) {
          return;
        }
        if (data['data'] === null) {
          this.$message.error("找不到流程信息！");
          return;
        }
        this.template.data = data['data'];
      });
    },
    getTemplateField() {
      indexItsmAdminTemplateApis.getTemplateField(this.template.templateId).then(data => {
        if (data === null) {
          return;
        }
        if (data['data'] === null) {
          this.$message.error("找不到流程信息！");
          return;
        }
        for (let i = 0; i < data['data'].length; i++) {
          this.$set(this.field.fieldIdIndex, data['data'][i]['fieldId'], data['data'][i]);
        }
        this.$set(this.field, 'data', data['data']);
      })
    },
    passOrder() {
      indexItsmOrderDetailsApis.passOrder(this.order.id, this.input.pass.reply, this.input.remark).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("已通过！");
        this.init();
      });
    },
    returnOrder() {
      indexItsmOrderDetailsApis.returnOrder(this.order.id, this.input.remark).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("已退回！");
        this.init();
      });
    },
    resubmit() {
      const keys = Object.keys(this.field.input);
      const res = [];
      if (this.orderName === '') {
        this.$message.error("“名称”不能为空！");
        return;
      }
      for (let i = 0; i < keys.length; i++) {
        const row = this.field.fieldIdIndex[keys[i]];
        // 检查必填项
        if (row['required'] === 1 &&
            ((row['fieldType'] === 1 && this.field.input[keys[i]] === null) ||
                (row['fieldType'] !== 1 && this.field.input[keys[i]] === ''))) {
          this.$message.error("必填项“" + row['title'] + "”不能为空！");
          return;
        }
        res.push({
          fieldId: Number(keys[i]),
          data: this.field.input[keys[i]],
        });
      }
      console.log(JSON.stringify(res));
      indexItsmOrderDetailsApis.resubmitOrder(this.order.id, JSON.stringify(res)).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("提交成功！");
        this.init();
      });
    },
    comment() {
      console.log(this.input.comment);
      const data = this.input.comment;
      if (data.evaluate === null || data.evaluate === 0) {
        this.$message.error("请进行星级评价！");
        return;
      }
      indexItsmOrderDetailsApis.commentOrder(this.order.id, data.comment, data.evaluate).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("提交成功！");
        this.init();
      });
    },
    logDialogClick() {
      this.logDialog.data = null;
      this.logDialog.visible = true;
      this.getLog();
    },
    getLog() {
      indexItsmOrderDetailsApis.getLog(this.order.id).then(data => {
        if (data === null) {
          return;
        }
        this.logDialog.data = data['data'];
      });
    },
  },
  mounted() {
    window.$IOD = this;
    this.init();
  },
  watch: {
    '$route.params.id': 'init',
    'template.templateId': function (val) {
      if (val !== null) {
        this.getTemplate();
        this.getTemplateField();
      }
    }
  }
}
</script>

<style scoped>

</style>