import api from "@/request/api";

export default {
  getType: () => {
    return api.post('/resource/type/get');
  },
  addType: (typeName, parentTypeId) => {
    return api.post('/resource/type/add', {
      typeName, parentTypeId
    });
  },
  setType: (typeId, typeName) => {
    return api.post('/resource/type/set', {
      typeId, typeName
    });
  },
  delType: (typeId) => {
    return api.post('/resource/type/del/' + typeId);
  }
};