import api from "@/request/api";

export default {
  getData(pageNo, pageSize, resourceId, itemId, days, desc) {
    return api.post('/monitor/data/get', {
      pageNo, pageSize, resourceId, itemId, days, desc
    });
  },
  getLatestData(resourceId) {
    return api.post('/monitor/data/get/' + resourceId);
  },
  getResourceByResourceId(resourceId) {
    return api.post('/monitor/resource/get-by-rid/' + resourceId);
  },
  getItemByTemplateId(templateId) {
    return api.post('/monitor/item/get-by-tid/' + templateId);
  }
};