<template>
  <div style="width: 100%; height: 100%" ref="body">

    <div style="height: 40px">
      <p style="font-weight: bold; margin-left: 5px; line-height: 40px; color: #606266; font-size: 14px; margin-right: 5px; user-select: none; cursor: default">
        {{ currentStatusId === null ? '' : "工单 / " + orderName[currentStatusId] }}
      </p>
    </div>
    <el-table :height="table.height" :data="order.data">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="orderName" label="名称"></el-table-column>
      <el-table-column prop="templateName" label="流程名称"></el-table-column>
      <el-table-column prop="currentUserName" label="当前处理人"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="100"><!--功能-->
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="choose(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @size-change="tableSizeChange"
        @current-change="tablePageChange"
        :current-page="order.pageNo"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="order.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        style="margin-top: 10px; height: 36px; float: right;"
        :total="order.total">
    </el-pagination>
  </div>
</template>
<script>

import indexItsmOrderApis from "@/apis/index-itsm/index-itsm-order-apis";

export default {
  name: 'IndexITSMOrder',
  data() {
    return {
      orderStatus: {
        running: 0,
        comment: 1,
        return: 2,
        end: 3,
      },
      orderName: {
        0: '进行中',
        1: '待评价',
        2: '已退回',
        3: '已结束',
      },
      currentStatusId: null,
      order: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
        data: null,
      },
      table: {
        height: null,
      }
    };
  },
  methods: {
    init() {
      console.log('init');
      Object.assign(this.$data, this.$options.data.call(this));
      const name = this.$route.path.split('/');
      if (!Object.keys(this.orderStatus).includes(name[name.length - 1])) {
        this.$router.replace('/index/itsm');
        return;
      }
      this.currentStatusId = this.orderStatus[name[name.length - 1]];
      this.getOrder();
    },
    tableResize() {
      if (!this.$refs.body) {
        this.table.height = "0";
      } else {
        this.table.height = this.$refs.body.offsetHeight - 100 + "px";
      }
    },
    tablePageChange(newPageNo) {
      this.order.pageNo = newPageNo;
      this.getOrder();
    },
    tableSizeChange(newPageSize) {
      this.order.pageNo = 1;
      this.order.pageSize = newPageSize;
      this.getOrder();
    },
    getOrder() {
      indexItsmOrderApis.getOrder(this.order.pageNo, this.order.pageSize, this.currentStatusId).then(data => {
        if (data === null) {
          return;
        }

        this.order.pageNo = data['data']['pageNo'];
        this.order.pageSize = data['data']['pageSize'];
        this.order.total = data['data']['totalSize'];
        this.order.data = data['data']['rows'];
        console.log(this.order);
      });
    },
    choose(row) {
      console.log(row);
      this.$router.push('/index/itsm/order-details/' + row['orderId']);
    }
  },
  mounted() {
    this.init();
    window.addEventListener('resize', this.tableResize);
    this.$nextTick(() => {
      this.tableResize();
    });
  },
  watch: {
    '$route.params.type': 'init'
  }
}
</script>

<style scoped>

</style>