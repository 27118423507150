<template>
  <div ref="body">

    <div style="height: 40px">
      <el-button size="small" type="success" @click="addAgentKeyDialog">生成Key</el-button>
      <el-button size="small" @click="getAgentKey">刷新</el-button>
    </div>
    <div>
      <el-table
          ref="table"
          :height="agentKey.table.height"
          :data="agentKey.table.data"
          v-loading="agentKey.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="key" label="Key"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>

        <el-table-column label="操作"><!-- 功能 -->
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="setAgentKeyDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delAgentKey(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <!-- agent key dialog -->
    <el-dialog
        :close-on-click-modal="false"
        :title="agentKey.dialog.title"
        :visible.sync="agentKey.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-input v-model="agentKey.dialog.remark" placeholder="备注"></el-input>
      </span>
      <span slot="footer">
        <el-button @click="agentKey.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="agentKeyDialogConfirm" :loading="agentKey.dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import indexMonitorAgentApis from "@/apis/index-monitor/index-monitor-agent-apis";

export default {
  name: 'IndexMonitorAgentKey',
  data() {
    return {
      agentKey: {
        table: {
          height: null,
          pageCurrent: 1,
          pageSize: 20,
          pageTotal: 0,
          loading: false,
          data: null
        },
        dialog: {
          title: '',
          visible: false,
          remark: '',
          rowInd: null
        }
      }
    };
  },
  methods: {
    getAgentKey() {
      indexMonitorAgentApis.getAgentKey().then(data => {
        if (data == null) {
          return;
        }
        this.$set(this.agentKey.table, 'data', data['data']);
      });
    },
    delAgentKey(row) {
      const id = row['agentKeyId'];
      this.$confirm("确定要删除“" + row.key + "”吗？").then(() => {
        indexMonitorAgentApis.delAgentKey(id).then(data => {
          if (data == null) {
            return;
          }
          this.$message.success("删除成功！");
          this.getAgentKey();
        });
      }).catch(() => {
      });
    },
    agentKeyDialog(title) {
      this.agentKey.dialog.title = title;
      this.agentKey.dialog.visible = true;
    },
    addAgentKeyDialog() {
      this.agentKey.dialog.remark = '';
      this.agentKeyDialog('生成');
    },
    agentKeyDialogConfirm() {
      switch (this.agentKey.dialog.title) {
        case "生成":
          this.addAgentKey();
          break;
        case "修改":
          this.setAgentKey();
          break;
      }
    },
    addAgentKey() {
      if (this.agentKey.dialog.remark === "") {
        this.$message.error("“备注”不能为空");
        return;
      }
      indexMonitorAgentApis.addAgentKey(this.agentKey.dialog.remark).then(data => {
        if (data == null) {
          return;
        }
        this.$message.success("生成成功！");
        this.agentKey.dialog.visible = false;
        this.getAgentKey();
      });
    },
    setAgentKeyDialog(row) {
      this.agentKey.dialog.remark = row.remark;
      this.agentKey.dialog.rowInd = row;
      this.agentKeyDialog('修改');
    },
    setAgentKey() {
      if (this.agentKey.dialog.remark === "") {
        this.$message.error("“备注”不能为空");
        return;
      }
      indexMonitorAgentApis.setAgentKeyRemark(this.agentKey.dialog.rowInd.agentKeyId, this.agentKey.dialog.remark).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功!");
        this.agentKey.dialog.visible = false;
        this.getAgentKey();
      });
    },
    agentKeyTableResize() {
      if (!this.$refs.body) {
        this.agentKey.table.height = "0";
      } else {
        this.agentKey.table.height = this.$refs.body.offsetHeight - 50 + "px";
      }
    },
  },
  mounted() {
    this.getAgentKey();
    window.addEventListener('resize', this.agentKeyTableResize);
    this.agentKeyTableResize();
  }
}
</script>

<style scoped>

</style>