import axios from "axios";
import ElementUI from "element-ui";
import router from "@/router";


const api = axios.create({
  baseURL: 'http://192.168.2.88:18123', timeout: 5000
});

api.interceptors.request.use(req => {
  const token = localStorage.getItem("token");
  if (token) {
    req.headers['token'] = token;
  }
  req.headers["Content-Type"] = 'application/json';
  return req;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(resp => {
  // console.log('resp', resp);
  if (resp.status !== 200) {
    ElementUI.Message.error("未知错误！");
    return null;
  }
  const data = resp.data;
  if (!data.hasOwnProperty('code')) {
    ElementUI.Message({
      message: "系统异常：" + JSON.stringify(data), type: "error", duration: 5000
    });
    return null;
  }
  if (data.code !== 1) {
    ElementUI.Message({
      message: data.message, type: "error", duration: 5000
    });
    if (data.code === 1001002) { // 登录失效
      localStorage.setItem('token', '');
      router.push('/login').then(() => {
      });
    }
    return null;
  }

  return resp['data'];
}, error => {
  console.log('resp error', error);
  if (error.status !== 200) {
    if (error.response.data.hasOwnProperty("error")) {
      ElementUI.Message.error(error.message + ": " + error.response.data.error);
    } else {
      ElementUI.Message.error(error.message);
    }
    return null;
  }

  return error;
});

export default api;