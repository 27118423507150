import api from "@/request/api";

export default {
  // resource group
  getResourceGroup: () => {
    return api.post('/monitor/resource/group/get');
  },
  addResourceGroup: (groupName) => {
    return api.post('/monitor/resource/group/add', {
      groupName
    });
  },
  delResourceGroup: (groupId) => {
    return api.post('/monitor/resource/group/del/' + groupId);
  },
  setResourceGroup: (groupId, groupName) => {
    return api.post('/monitor/resource/group/set', {
      groupId, groupName
    });
  },

  // resource
  getResource: (groupId) => {
    return api.post('/monitor/resource/get/' + groupId);
  },
  addResource: (templateId, agentId, resourceGroupId, resourceName) => {
    return api.post('/monitor/resource/add', {
      templateId, agentId, resourceGroupId, resourceName
    });
  },
  delResource: (resourceId) => {
    return api.post('/monitor/resource/del/' + resourceId);
  },
  setResource: (resourceId, resourceName) => {
    return api.post('/monitor/resource/set', {
      resourceId, resourceName
    });
  }
};