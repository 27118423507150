import api from "@/request/api";

export default {
  // item group
  getItemGroup: (templateId) => {
    return api.post('/monitor/item/group/get/' + templateId);
  },
  addItemGroup: (groupName, templateId, interval, topItemId, isTable) => {
    return api.post('/monitor/item/group/add', {
      groupName, templateId, interval, topItemId, isTable
    });
  },
  delItemGroup: (groupId) => {
    return api.post('/monitor/item/group/del/' + groupId);
  },
  setItemGroup: (groupId, groupName, interval, topItemId, isTable) => {
    return api.post('/monitor/item/group/set', {
      groupId, groupName, interval, topItemId, isTable
    });
  },

  // item
  getItem: (groupId) => {
    return api.post('/monitor/item/get/' + groupId);
  },
  addItem: (itemName, itemKeyName, itemGroupId, itemType) => {
    return api.post('/monitor/item/add', {
      itemName, itemKeyName, itemGroupId, itemType
    });
  },
  delItem: (itemId) => {
    return api.post('/monitor/item/del/' + itemId);
  },
  setItem: (itemId, itemName, itemKeyName, itemType) => {
    return api.post('/monitor/item/set', {
      itemId, itemName, itemKeyName, itemType
    });
  }
};