import api from "@/request/api";

export default {
  getTemplate: () => {
    return api.post('/monitor/template/get');
  },
  getTemplateOne: (templateId) => {
    return api.post('/monitor/template/get/' + templateId);
  },
  addTemplate: (templateName) => {
    return api.post('/monitor/template/add', {
      templateName
    });
  },
  delTemplate: (templateId) => {
    return api.post('/monitor/template/del/' + templateId);
  },
  setTemplate: (templateId, templateName) => {
    return api.post('/monitor/template/set', {
      templateId, templateName
    });
  }
};