<template>
  <div class="home">
    <div class="header">
      <index-nav-menu></index-nav-menu>
    </div>
    <div class="main">
      <router-view style="padding: 20px;"/>
    </div>
  </div>
</template>

<script>
import IndexNavMenu from "@/components/IndexNavMenu.vue";

export default {
  name: 'Index',
  components: {IndexNavMenu},
  data() {
    return {}
  },
  methods: {},
  mounted() {
    window.vue = this;
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  width: 100%;
}

.header {
  height: 60px;
  padding: 0 20px;
}

.main {
  height: calc(100% - 60px);
  overflow-y: auto;
}
</style>