<template>
  <div ref="body">
    <div style="height: 40px; width: 100%;">
      <table style="width: 100%;">
        <tbody style="width: 100%;">
        <tr>
          <td>
            <!-- TODO: 添加模板名称 -->
          </td>
          <td style="float: right">
            <el-button type="success" size="small" @click="addFieldDialog">新建</el-button>
            <el-button type="primary" size="small" @click="getFieldByTemplateId">刷新</el-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <el-table
        :height="field.table.height"
        :data="field.data"
        v-loading="field.table.loading">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="title" label="名称"></el-table-column>
      <el-table-column prop="fieldTypeText" label="类型"></el-table-column>
      <el-table-column prop="requiredText" label="是否必填"></el-table-column>
      <el-table-column prop="options" label="选项"></el-table-column>
      <el-table-column label="操作" width="100"><!--功能-->
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="setFieldDialog(scope.row)">修改</el-button>
          <el-button size="small" type="text" @click="delField(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :close-on-click-modal="false"
        :title="dialog.title"
        :visible.sync="dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span v-if="dialog.data !== null">
        <el-form label-width="80px">
          <el-form-item label="名称">
            <el-input v-model="dialog.data.title"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="dialog.data.fieldType" style="width: 100%;" @change="dialog.data.options = ''">
              <el-option label="普通文本" :value="0"></el-option>
              <el-option label="下拉选项" :value="1"></el-option>
              <el-option label="长文本" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否必填">
            <el-select v-model="dialog.data.required" style="width: 100%;">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选项" v-if="dialog.data.fieldType === 1">
            <el-input v-model="dialog.data.options" placeholder="使用英文逗号(,)分隔"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="dialogConfirm" :loading="dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import indexItsmAdminTemplateApis from "@/apis/index-itsm/index-itsm-admin-template-apis";

export default {
  name: 'IndexITSMAdminTemplateField',
  data() {
    return {
      templateId: null,
      field: {
        data: null,
        table: {
          height: null,
          loading: null,
        }
      },
      dialog: {
        title: '',
        loading: false,
        visible: false,
        data: null,
      }
    }
  },
  methods: {
    init() {
      console.log('init', this.$route.params['id']);
      Object.assign(this.$data, this.$options.data.call(this));
      this.templateId = parseInt(this.$route.params['id']);

      this.getFieldByTemplateId();
    },
    getFieldByTemplateId() {
      this.field.table.loading = true;
      indexItsmAdminTemplateApis.getTemplateField(this.templateId).then(data => {
        this.field.table.loading = false;
        if (data === null) {
          return;
        }
        const fieldTypeText = {
          0: '文本',
          1: '下拉选项',
          2: '长文本'
        };
        const requiredText = {
          0: '非必填',
          1: '必填'
        };
        for (let i = 0; i < data['data'].length; i++) {
          if (Object.keys(fieldTypeText).includes(String(data['data'][i]['fieldType']))) {
            data['data'][i]['fieldTypeText'] = fieldTypeText[data['data'][i]['fieldType']];
          } else {
            data['data'][i]['fieldTypeText'] = "";
          }
          if (Object.keys(requiredText).includes(String(data['data'][i]['required']))) {
            data['data'][i]['requiredText'] = requiredText[data['data'][i]['required']];
          } else {
            data['data'][i]['requiredText'] = "";
          }
          if (data['data'][i]['fieldType'] !== 1) {
            data['data'][i]['options'] = "--";
          }
        }
        this.$set(this.field, 'data', data['data']);
      });
    },
    tableResize() {
      if (!this.$refs.body) {
        this.field.table.height = "0";
      } else {
        this.field.table.height = this.$refs.body.offsetHeight - 50 + "px";
      }
    },
    dialogConfirm() {
      if (this.dialog.title === '新增') {
        this.addField();
      }
      if (this.dialog.title === '修改') {
        this.setField();
      }
    },
    addFieldDialog() {
      this.$set(this.dialog, 'data', {
        title: '',
        required: null,
        fieldType: null,
        options: '',
      });
      this.dialog.title = '新增';
      this.dialog.visible = true;
    },
    fieldFormCheck() {
      if (this.dialog.data['title'] === '') {
        this.$message.error("“名称”不能为空！");
        return false;
      }
      if (this.dialog.data.fieldType === null) {
        this.$message.error("请选择“类型”！");
        return false;
      }
      if (this.dialog.data.required === null) {
        this.$message.error("请选择“是否必填”！");
        return false;
      }
      if (this.dialog.data.fieldType === 1 && this.dialog.data.options === '') {
        this.$message.error("“选项”不能为空！");
        return false;
      }
      return true;
    },
    addField() {
      if (!this.fieldFormCheck()) {
        return;
      }
      const row = this.dialog.data;
      indexItsmAdminTemplateApis.addTemplateField(
          row.title, this.templateId, row.required, row.fieldType, row.options
      ).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("新建成功！");
        this.getFieldByTemplateId();
        this.dialog.visible = false;
      });
    },
    setFieldDialog(row) {
      this.$set(this.dialog, 'data', JSON.parse(JSON.stringify(row)));
      const data = this.dialog.data;
      if (data.fieldType !== 1) {
        data.options = '';
      }
      this.dialog.title = '修改';
      this.dialog.visible = true;
    },
    setField() {
      if (!this.fieldFormCheck()) {
        return;
      }
      const row = this.dialog.data;
      indexItsmAdminTemplateApis.setTemplateField(
          row['fieldId'], row['title'], this.templateId, row['required'], row['fieldType'], row['options']
      ).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功！");
        this.getFieldByTemplateId();
        this.dialog.visible = false;
      });
    },
    delField(row) {
      this.$confirm("确定要删除“" + row['title'] + "”吗？").then(() => {
        indexItsmAdminTemplateApis.delTemplateField(row['fieldId']).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功！");
          this.getFieldByTemplateId();
          this.dialog.visible = false;
        });
      }).catch(() => {
      });
    },
  },
  mounted() {
    window.$IATF = this;
    this.init();
    window.addEventListener('resize', this.tableResize);
    this.$nextTick(() => {
      this.tableResize();
    });

  },
  watch: {
    '$route.params.id': 'init'
  }
}
</script>

<style scoped>

</style>