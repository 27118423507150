import api from "@/request/api";

export default {
  getResourceByResourceId(resourceId) {
    return api.post('/resource/get/' + resourceId);
  },

  getResourceValue(resourceId) {
    return api.post('/resource/value/get/' + resourceId);
  },

  setResourceValue: function (resourceValueArr) {
    return api.post('/resource/value/set', resourceValueArr);
  }
};