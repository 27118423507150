<template>
  <div v-if="data != null" ref="body">
    <el-breadcrumb separator="/" style="line-height: 2;">
      <el-breadcrumb-item v-if="data.templateName !== null" style="font-weight: bold">{{
          data.templateName
        }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ data.display ? "指标" : "指标组" }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div>

      <el-button size="small" type="primary" v-if="!data.display" @click="data.display=!data.display">查看指标
      </el-button>
      <el-button size="small" type="primary" v-if="data.display" @click="data.display=!data.display">查看指标组
      </el-button>
      <el-button size="small" type="success" v-if="data.display" @click="addItemDialog">新增指标</el-button>

      <el-button size="small" type="success" v-if="!data.display" @click="addItemGroupDialog">新增指标组</el-button>

      <el-button size="small" @click="data.display?getItem():getItemGroup()">刷新</el-button>

    </div>
    <div v-if="data.display">
      <el-tabs v-model="data.itemGroup.tabs.active" @tab-click="getItem" style="height: 50px;">
        <el-tab-pane v-for="v in data.itemGroup.data" :label="v.groupName" :name="v.groupId.toString()">
        </el-tab-pane>
      </el-tabs>
      <el-table
          ref="table1"
          :height="data.item.table.height"
          :data="itemData"
          v-loading="data.item.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="itemName" label="指标名称"></el-table-column>
        <el-table-column prop="itemKey" label="指标Key"></el-table-column>
        <el-table-column prop="itemTypeStr" label="指标类型"></el-table-column>

        <el-table-column label="操作"><!--功能-->
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="setItemDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delItemConfirm(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div v-if="!data.display">
      <el-table
          ref="table2"
          :height="data.itemGroup.table.height"
          :data="itemGroupData"
          v-loading="data.itemGroup.table.loading"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="groupName" label="组名"></el-table-column>
        <el-table-column prop="interval" label="更新周期(s)"></el-table-column>
        <el-table-column prop="isTableStr" label="是否表格数据"></el-table-column>
        <el-table-column prop="topItemName" label="置顶指标"></el-table-column>

        <el-table-column label="操作"><!--功能-->
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="setItemGroupDialog(scope.row)">修改</el-button>
            <el-button size="small" type="text" @click="delItemGroupConfirm(scope.row)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="data.dialog.title"
        :visible.sync="data.dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span v-if="data.dialog.visible">
        <!-- 指标 -->
        <el-form label-width="100px" v-if="data.display">
          <el-form-item label="指标名称">
            <el-input v-model="data.dialog.data.itemName"></el-input>
          </el-form-item>
          <el-form-item label="指标Key">
            <el-input v-model="data.dialog.data.itemKey"></el-input>
          </el-form-item>
          <el-form-item label="指标类型">
            <el-select v-model="data.dialog.data.itemType" style="width: 100%;">
              <el-option label="文本" :value="0"></el-option>
              <el-option label="纯数字" :value="1"></el-option>
              <el-option label="百分比" :value="2"></el-option>
              <el-option label="大小" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 指标组 -->
        <el-form label-width="100px" v-if="!data.display">
          <el-form-item label="组名">
            <el-input v-model="data.dialog.data.groupName"></el-input>
          </el-form-item>
          <el-form-item label="更新周期(s)">
            <el-input v-model="data.dialog.data.interval" placeholder="1-600"></el-input>
          </el-form-item>
          <el-form-item label="是否表格数据">
            <el-select v-model="data.dialog.data.isTable" style="width: 100%">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="data.dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="dialogConfirm" :loading="false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import indexMonitorItemApis from "@/apis/index-monitor/index-monitor-item-apis";
import indexMonitorTemplateApis from "@/apis/index-monitor/index-monitor-template-apis";

export default {
  name: 'IndexMonitorTemplateItem',
  data() {
    return {
      dataTemplate: JSON.stringify(
          {
            id: null,
            templateName: null,
            itemGroup: {
              data: null,
              tabs: {
                active: null
              },
              table: {
                height: null,
                loading: false,
              }
            },
            item: {
              data: null,
              table: {
                height: null,
                loading: false,
              },
            },
            display: false, // 0 指标组，1 指标
            dialog: {
              title: "",
              visible: false,
              data: null,
            },
          }
      ),
      dialogItemGroupTemplate: JSON.stringify(
          {
            groupName: '',
            templateId: null,
            interval: null,
            topItemId: -1,
            isTable: 0,
          }
      ),
      dialogItemTemplate: JSON.stringify(
          {
            itemName: '',
            itemKey: '',
            itemGroupId: null,
            itemType: null // 指标类型：0 文本，1 纯数字，2 百分比，3 大小
          }
      ),
      data: null,
    };
  },
  methods: {
    init() {
      this.data = null;
      this.$set(this, 'data', JSON.parse(this.dataTemplate));
      this.data.id = parseInt(this.$route.params.id);
      this.getTemplateName();
      this.getItemGroup();
    },
    getTemplateName() {
      indexMonitorTemplateApis.getTemplateOne(this.data.id).then(data => {
        if (data === null) {
          return;
        }
        if (data['data'] === null) {
          return;
        }
        this.data.templateName = data['data'].templateName;
      });
    },
    getItemGroup() {
      this.data.itemGroup.data = null;
      indexMonitorItemApis.getItemGroup(this.data.id).then(data => {
        if (data === null) {
          return;
        }

        if (data['data'].length !== 0) {
          this.data.itemGroup.tabs.active = data['data'][0]['groupId'].toString();
          this.getItem();
        }
        this.data.itemGroup.data = data['data'];
      });
    },
    getItem() {
      this.data.item.data = null;
      if (this.data.itemGroup.tabs.active === null) {
        this.data.itemGroup.tabs.active = -1;
      }
      indexMonitorItemApis.getItem(this.data.itemGroup.tabs.active).then(data => {
        if (data === null) {
          return;
        }
        this.$set(this.data.item, 'data', data['data']);
      });
    },
    addItemGroupDialog() {
      this.data.dialog.data = JSON.parse(this.dialogItemGroupTemplate);
      this.data.dialog.title = '添加指标组';
      this.data.dialog.visible = true;
    },
    setItemGroupDialog(row) {
      this.data.dialog.data = JSON.parse(JSON.stringify(row));
      this.data.dialog.title = '修改指标组';
      this.data.dialog.visible = true;
    },
    dialogConfirm() {
      if (!this.data.display) {
        switch (this.data.dialog.title) {
          case '添加指标组':
            this.addItemGroup();
            break;
          case '修改指标组':
            this.setItemGroup();
            break;
        }
      } else {
        switch (this.data.dialog.title) {
          case '添加指标':
            this.addItem();
            break;
          case '修改指标':
            this.setItem();
            break;
        }
      }
    },
    checkItemForm(data) {
      if (data['itemName'] === "") {
        this.$message.error("请输入“指标名称”");
        return false;
      }
      if (data['itemKey'] === "") {
        this.$message.error("请输入“指标Key”");
        return false;
      }
      if (data['itemType'] === null) {
        this.$message.error("请选择“指标类型”");
        return false;
      }
      if (data['itemType'] < 0 || data['itemType'] > 3) {
        this.$message.error("“指标类型”选择错误");
        return false;
      }
      return true;
    },
    delItemConfirm(row) {
      this.$confirm("你确定要删除“" + row['itemName'] + "”吗？").then(() => {
        indexMonitorItemApis.delItem(row['itemId']).then(data => {
          if (data === null) {
            return;
          }
          this.getItem();
          this.$message.success("删除成功！");
        });
      }).catch(() => {
      });
    },
    setItem() {
      const data = this.data.dialog.data;
      if (!this.checkItemForm(data)) {
        return;
      }
      const groupId = this.data.itemGroup.tabs.active;
      const keyName = "group" + groupId + "." + data['itemKey'];
      indexMonitorItemApis.setItem(
          data['itemId'], data['itemName'], keyName, data['itemType']
      ).then(data => {
        if (data === null) {
          return;
        }
        this.getItem();
        this.$message.success("修改成功！");
        this.data.dialog.visible = false;
      })
    },
    addItem() {
      const data = this.data.dialog.data;
      if (!this.checkItemForm(data)) {
        return;
      }
      const groupId = this.data.itemGroup.tabs.active;
      const keyName = "group" + groupId + "." + data['itemKey'];
      indexMonitorItemApis.addItem(data['itemName'], keyName, groupId, data['itemType']).then(data => {
        if (data === null) {
          return;
        }
        this.getItem();
        this.$message.success("添加成功");
        this.data.dialog.visible = false;
      });
    },
    checkItemGroupForm(data) {
      if (data['groupName'] === "") {
        this.$message.error("请输入“组名”");
        return false;
      }
      if (data['interval'] === null || data['interval'] === '') {
        this.$message.error("请输入“更新周期”");
        return false;
      }
      const interval = parseInt(data['interval']);
      data['interval'] = interval.toString();
      if (!(interval >= 1 && interval <= 600)) {
        this.$message.error("“更新周期”范围错误，必须在1~600内");
        return false;
      }
      if (data['isTable'] === null) {
        this.$message.error("请选择“是否表格数据”");
        return false;
      }
      return true;
    },
    delItemGroupConfirm(row) {
      this.$confirm("你确定要删除“" + row['groupName'] + "”吗？").then(() => {
        indexMonitorItemApis.delItemGroup(row['groupId']).then(data => {
          if (data === null) {
            return;
          }
          this.getItemGroup();
          this.$message.success("删除成功！");
        });
      }).catch(() => {
      });
    },
    setItemGroup() {
      const data = this.data.dialog.data;
      if (!this.checkItemGroupForm(data)) {
        return;
      }
      indexMonitorItemApis.setItemGroup(
          data['groupId'], data['groupName'], parseInt(data['interval']), data['topItemId'], data['isTable']
      ).then(data => {
        if (data === null) {
          return;
        }
        this.getItemGroup();
        this.$message.success("修改成功！");
        this.data.dialog.visible = false;
      });
    },
    addItemGroup() {
      const data = this.data.dialog.data;
      if (!this.checkItemGroupForm(data)) {
        return;
      }
      indexMonitorItemApis.addItemGroup(
          data['groupName'], this.data.id, parseInt(data['interval']), -1, data['isTable']
      ).then(data => {
        console.log(data);
        if (data === null) {
          return;
        }
        this.getItemGroup();
        this.$message.success("添加成功！");
        this.data.dialog.visible = false;
      });
    },
    addItemDialog() {
      this.data.dialog.data = JSON.parse(this.dialogItemTemplate);
      this.data.dialog.title = '添加指标';
      this.data.dialog.visible = true;
    },
    setItemDialog(row) {
      this.data.dialog.data = JSON.parse(JSON.stringify(row));
      this.data.dialog.title = '修改指标';
      this.data.dialog.visible = true;
    },
    tableResize() {
      if (!this.$refs.body) {
        this.data.itemGroup.table.height = "0";
        this.data.item.table.height = "0";
      } else {
        this.data.itemGroup.table.height = this.$refs.body.offsetHeight - 70 + "px";
        this.data.item.table.height = this.$refs.body.offsetHeight - 120 + "px";
      }
    }

  },
  computed: {
    itemData() {
      const data = this.data.item.data;
      if (data === null) {
        return;
      }
      const itemTypeInd = {
        0: '文本',
        1: '纯数字',
        2: '百分比',
        3: '大小'
      };
      for (let i = 0; i < data.length; i++) {
        data[i]['itemTypeStr'] = itemTypeInd[data[i]['itemType']];
        try {
          data[i]['itemKey'] = data[i]['itemKeyName'].split('.')[1];
        } catch (e) {
          data[i]['itemKey'] = "";
        }
      }
      return data;
    },
    itemGroupData() {
      const data = this.data.itemGroup.data;
      if (data === null) {
        return;
      }
      for (let i = 0; i < data.length; i++) {
        data[i]['isTableStr'] = (data[i]['isTable'] === 1) ? '是' : '否';
        data[i]['topItemName'] = (data[i]['isTable'] === 0) ? data[i]['topItemName'] : '--';
        data[i]['topItemId'] = (data[i]['isTable'] === 0) ? data[i]['topItemId'] : -1;
      }
      return data;
    }
  },
  mounted() {
    this.init();
    window.$vueIMTI = this;
    window.addEventListener('resize', this.tableResize);
    this.tableResize();
  },
  watch: {
    '$route.params.id': 'init',
    'data.display': function (val) {
      if (val) {
        this.getItem();
      } else {
        this.getItemGroup();
      }
    },
    'data.itemGroup.data': function (val) {
      if (val !== null) {
        this.tableResize();
      }
    },
    'data.item.data': function (val) {
      if (val !== null) {
        this.tableResize();
      }
    }
  }
}
</script>

<style scoped>

</style>