<template>
  <div style="width: 100%;">
    <table style="width: 100%;">
      <tbody style="width: 100%;">
      <tr>
        <td style="text-align: left">
          <el-menu :default-active="navActive" mode="horizontal" router>
            <el-menu-item index="/index/itsm" v-if="sysPermission[1]">工单</el-menu-item>
            <el-menu-item index="/index/monitor" v-if="sysPermission[2]">监控管理</el-menu-item>
            <el-menu-item index="/index/resource" v-if="sysPermission[3]">资源管理</el-menu-item>
          </el-menu>
        </td>
        <td style="width: 50px; border-bottom: solid 1px #e6e6e6; text-align: right">
        </td>
        <td style="text-align: right; border-bottom: solid 1px #e6e6e6;width: 100px" v-if="userInfo !== null">
          <span style="margin-right: 10px; color: #606266; font-size: 14px">{{
              userInfo['displayName']
            }}</span>
          <el-button type="text" @click="logout">退出</el-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import indexGlobalApis from "@/apis/index-global-apis";

export default {
  name: 'NavMenu',
  data() {
    return {
      sysPermission: {
        1: true,
        2: false,
        3: false
      },
      userInfo: null,
    };
  },
  methods: {
    init() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.getPermission();
    },
    getPermission() {
      if (this.$store.state.userInfo) {
        this.setPermission(this.$store.state.userInfo);
        this.userInfo = this.$store.state.userInfo;
        return;
      }
      indexGlobalApis.userInfo().then(res => {
        if (res === null) {
          return;
        }
        this.$store.state.userInfo = res.data;
        this.userInfo = res.data;
        this.setPermission(this.$store.state.userInfo);
      });
    },
    setPermission(data) {
      if (!data || !data.permissions) {
        this.$message.error('未知错误1003');
        return;
      }
      const rows = data.permissions;
      for (let i = 0; i < rows.length; i++) {
        this.sysPermission[rows[i]['sysId']] = true;
      }
    },
    logout() {
      if (this.userInfo === null) {
        return;
      }
      indexGlobalApis.logout();
      this.$store.state.userInfo = null;
      this.$message.success("退出成功");
      this.$router.push('/login');
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    navActive() {
      const path = this.$route.path;
      const pathArr = path.split('/');
      return pathArr.slice(0, 3).join('/');
    }
  }
}
</script>

<style scoped>

</style>