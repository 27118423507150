<template>
  <div>
    <div style="width: 100%; height: 40px;">
      <span
          style="font-weight: bold; margin-left: 5px; line-height: 40px; color: #606266; font-size: 14px; margin-right: 5px; user-select: none; cursor: default">{{
          (current === 0) ? "选择流程" : "填写工单"
        }}</span>
      <el-button v-if="current === 1" size="small" type="text" @click="back" style="margin-left: 10px;">返回</el-button>

    </div>
    <div v-if="current === 0" style="width: calc(100% - 10px); height: calc(100% - 50px); padding: 5px">
      <el-table
          :height="template.table.height"
          :data="template.data"
          v-loading="template.table.loading">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="optUserName" label="处理人"></el-table-column>
        <el-table-column label="操作" width="100"><!--功能-->
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="choose(scope.row)">选择</el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div v-if="current === 1" style="width: calc(100% - 10px); height: calc(100% - 50px); padding: 5px">
      <el-form style="height: 100%;" v-loading="field.loading" label-width="100px" v-if="!field.loading">
        <div>
          <el-form-item label="流程">
            <p style="color: #606266; font-size: 14px;">{{ field.currentTemplate.name }}</p>
          </el-form-item>
          <el-form-item label="处理人">
            <p style="color: #606266; font-size: 14px;">{{ field.currentTemplate['optUserName'] }}</p>
          </el-form-item>
          <el-form-item label="名称" required>
            <el-input style="width: 100%" v-model="orderName"></el-input>
          </el-form-item>
        </div>
        <div v-for="f in field.data">
          <el-form-item v-if="f.fieldType === 0" :label="f.title" :required="f.required === 1">
            <!-- width: 80%; min-width: 300px; max-width: 550px; -->
            <el-input style="width: 100%" v-model="field.input[f.fieldId]"></el-input>
          </el-form-item>
          <el-form-item v-if="f.fieldType === 1" :label="f.title" :required="f.required === 1">
            <el-select style="width: 100%" v-model="field.input[f.fieldId]">
              <el-option v-for="o in f.options.split(',')" :label="o" :value="o"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="f.fieldType === 2" :label="f.title" :required="f.required === 1">
            <el-input style="width: 100%" :autosize="{minRows: 5, maxRows: 5}"
                      resize="vertical"
                      v-model="field.input[f.fieldId]" type="textarea" rows="5"></el-input>
          </el-form-item>
        </div>
        <el-button style="float: right" type="success" @click="submit">提交</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>

import indexItsmAdminTemplateApis from "@/apis/index-itsm/index-itsm-admin-template-apis";
import indexItsmNewApis from "@/apis/index-itsm/index-itsm-new-apis";

export default {
  name: 'IndexITSMNew',
  data() {
    return {
      current: 0,
      templateId: null,
      template: {
        data: null,
        table: {
          height: null,
          loading: false,
        }
      },
      field: {
        loading: false,
        data: null,
        input: {},
        fieldIdIndex: null,
        currentTemplate: null,
      },
      orderName: '',
    }
  },
  methods: {
    init() {
      this.getAllTemplate();
    },
    getAllTemplate() {
      indexItsmAdminTemplateApis.getTemplate().then(data => {
        if (data === null) {
          return;
        }
        this.$set(this.template, 'data', data['data']);
      })
    },
    choose(row) {
      this.templateId = row['templateId'];
      this.orderName = '';
      this.field.currentTemplate = row;
      this.current = 1;
      this.getTemplateField();
      console.log(this.templateId);
    },
    back() {
      this.current = 0;
    },
    getTemplateField() {
      this.field.data = null;
      this.field.loading = true;
      indexItsmAdminTemplateApis.getTemplateField(this.templateId).then(data => {
        this.field.loading = false;
        if (data === null) {
          return;
        }
        const ind = {};
        for (let i = 0; i < data['data'].length; i++) {
          const row = data['data'][i];
          const fieldType = row['fieldType'];

          if (fieldType === 0 || fieldType === 2) {
            this.$set(this.field.input, row['fieldId'], '');
          } else {
            this.$set(this.field.input, row['fieldId'], null);
          }
          ind[data['data'][i]['fieldId']] = data['data'][i];
        }
        this.$set(this.field, 'fieldIdIndex', ind);
        this.field.data = data['data'];
        console.log(this.field);
      });
    },
    submit() {
      const keys = Object.keys(this.field.input);
      const res = [];
      if (this.orderName === '') {
        this.$message.error("“名称”不能为空！");
        return;
      }
      for (let i = 0; i < keys.length; i++) {
        const row = this.field.fieldIdIndex[keys[i]];
        // 检查必填项
        if (row['required'] === 1 &&
            ((row['fieldType'] === 1 && this.field.input[keys[i]] === null) ||
                (row['fieldType'] !== 1 && this.field.input[keys[i]] === ''))) {
          this.$message.error("必填项“" + row['title'] + "”不能为空！");
          return;
        }
        res.push({
          fieldId: Number(keys[i]),
          data: this.field.input[keys[i]],
        });
      }
      console.log(JSON.stringify(res));

      indexItsmNewApis.createOrder(this.templateId, JSON.stringify(res), this.orderName).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("添加成功！");
        this.$router.push('/index/itsm/order-details/' + data['data']);
      });
    }
  },
  mounted() {
    window.$IN = this;
    this.init();
  }
}
</script>

<style scoped>

</style>