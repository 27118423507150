import api from "@/request/api";

export default {
  getResource: (typeId, pageNo, pageSize) => {
    return api.post('/resource/get', {
      typeId, pageNo, pageSize
    });
  },
  addResource: (resourceName, typeId, groupId, remark) => {
    return api.post('/resource/add', {
      resourceName, typeId, groupId, remark
    });
  },
  setResource: (resourceId, resourceName, typeId, groupId, remark) => {
    return api.post('/resource/set', {
      resourceId, resourceName, typeId, groupId, remark
    });
  },
  delResource: (resourceId) => {
    return api.post('/resource/del/' + resourceId);
  }
};