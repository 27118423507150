<template>
  <div class="details-div" style="height: 100%; overflow-y: auto; width: 100%">
    <div style="height: 36px;">
      <table style="width: 100%;">
        <tr>
          <td>
            <el-breadcrumb separator="/">
              <el-breadcrumb-item>资源预览</el-breadcrumb-item>
              <el-breadcrumb-item v-if="resource.data !== null">{{ resource.data.resourceName }}</el-breadcrumb-item>
            </el-breadcrumb>
          </td>
          <td style="text-align: right">
            <el-button type="primary" size="small" @click="edit" v-if="!editing">编辑</el-button>
            <el-button size="small" @click="cancelEdit" v-if="editing">取消</el-button>
            <el-button type="success" size="small" @click="save" v-if="editing">确定</el-button>
          </td>
        </tr>
      </table>
    </div>
    <div style="overflow-y: auto; height: calc(100% - 56px);">
      <div v-if="procFinished">
        <!-- 普通类型 -->
        <el-form label-width="150" style="display: flex; flex-direction: row; flex-wrap: wrap">
          <el-form-item v-for="i in dataCurrent.plain" :label="i.displayName"
                        style="margin-right: 20px;">
            <el-input :disabled="!editing" v-model="i.value" style="width: 200px;"></el-input>
          </el-form-item>
          <!-- 下拉选项 -->
          <el-form-item v-for="i in dataCurrent.option" :label="i.displayName" style="margin-right: 20px;">
            <el-select :disabled="!editing" v-model="i.value" placeholder="" style="width: 200px;">
              <el-option label="(空)" value=""></el-option>
              <el-option
                  v-for="j in i['optionArr']"
                  :value="j"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="procFinished">
        <!-- 表格类型 -->
        <div v-for="i in dataCurrent.table" style="margin-top: 10px;">
          <div>
            <span class="el-form-item__label" style="line-height: 30px;">{{ i.displayName }}</span>
            <el-button @click="dialogEditColumn(i)" size="small" type="primary" style="height: 30px;"
                       v-if="editing && !i.editing">
              编辑表头
            </el-button>
            <el-button @click="tableEdit(i)" size="small" type="primary" style="height: 30px;"
                       v-if="editing && !i.editing">编辑内容
            </el-button>
            <el-button v-if="editing && i.editing" type="success" size="small" @click="tableAddRow(i)"
                       icon="el-icon-circle-plus-outline"></el-button>
            <el-button v-if="editing && i.editing" size="small" type="primary" @click="tableSave(i)">保存</el-button>
            <el-button v-if="editing && i.editing" size="small" @click="i.editing = false">取消</el-button>
          </div>
          <div v-if="!i.editing">
            <el-table
                :data="i['value']['data']"
            >
              <el-table-column
                  v-for="(j, index) in i['value']['column']"
                  :label="j"
                  :prop="String(index)"
              ></el-table-column>

            </el-table>
          </div>
          <div v-if="editing && i.editing">
            <el-table
                :data="i['valueEdit']['data']"
            >
              <el-table-column
                  v-for="(j, index) in i['valueEdit']['column']"
                  :label="j"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row[index]"></el-input>
                </template>

              </el-table-column>

              <el-table-column label="" width="100">
                <template slot-scope="scope">
                  <el-button type="danger" size="small" icon="el-icon-remove-outline"
                             @click="tableDelLine(i, scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="dialog.title"
        :visible.sync="dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-form label-width="100px" class="dialog-form-column" style="height: 300px; overflow-y: auto">
            <div v-for="(col, colInd) in dialog.column">
              <el-input style="width: calc(100% - 65px)" :key="colInd" v-model="dialog.column[colInd]"></el-input>
              <el-button @click="columnRemove(colInd)" style="margin-left: 20px;" type="danger" size="small"
                         icon="el-icon-remove-outline"></el-button>
            </div>
        </el-form>
      </span>
      <span slot="footer">
        <el-button @click="columnAdd" type="success" icon="el-icon-circle-plus-outline"></el-button>
        <el-button @click="dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="columnSave" :loading="dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import indexResourceAttributeApis from "@/apis/index-resource/index-resource-attribute-apis";
import indexResourceDataDetailsApis from "@/apis/index-resource/index-resource-data-details-apis";

export default {
  name: 'IndexResourceDataDetails',
  data() {
    return {
      resourceId: -1,
      resource: {
        data: null,
        finished: false
      },
      resourceValue: {
        data: null,
        finished: false
      },
      resourceAttribute: {
        data: null,
        finished: false
      },
      data: {
        plain: [],
        table: [],
        option: []
      },
      dataEdit: null,
      dataCurrent: null,
      process: false,
      procValInd: {},
      procValKeys: null,
      procFinished: false,
      editing: false,
      dialog: {
        title: '',
        visible: false,
        loading: false,
        column: null,
        columnCurrent: null,
      }
    };
  },
  methods: {
    init() {
      this.editing = false;
      this.procFinished = false;
      this.resource.finished = false;
      this.resourceValue.finished = false;
      this.resourceAttribute.finished = false;
      this.process = false;
      this.procValInd = {};
      this.procValKeys = null;
      this.resource.data = null;
      this.resourceValue.data = null;
      this.resourceAttribute.data = null;
      this.data.plain = [];
      this.data.table = [];
      this.data.option = [];
      this.dataEdit = null;
      this.procValInd = {};
      this.procValKeys = null;
      this.resourceId = parseInt(this.$route.params.id);
      this.getResource();
    },
    getResource() {
      this.resource.finished = false;
      indexResourceDataDetailsApis.getResourceByResourceId(this.resourceId).then(res => {
        if (res === null) {
          return;
        }
        const data = res['data'];
        if (data === null) {
          this.$message.error("找不到该资源！");
          return;
        }
        this.resource.data = data;
        this.resource.finished = true;
      });
    },
    getResourceAttribute() {
      this.resourceAttribute.finished = false;
      indexResourceAttributeApis.getAttribute(1, -1, this.resource.data['groupId']).then(res => {
        if (res === null) {
          return;
        }
        const data = res['data']['rows'];
        this.resourceAttribute.data = data;

        for (let i = 0; i < data.length; i++) {
          const row = data[i];
          switch (row['attributeType']) {
            case 0:
              this.data.plain.push(row);
              break;
            case 1:
              this.data.table.push(row);
              break;
            case 2:
              this.data.option.push(row);
              break;
          }
        }
        console.log(this.data);
        this.resourceAttribute.finished = true;
      });
    },
    getResourceValue() {
      this.resourceValue.finished = false;
      indexResourceDataDetailsApis.getResourceValue(this.resourceId).then(res => {
        if (res === null) {
          return;
        }
        this.resourceValue.data = res['data'];
        this.resourceValue.finished = true;
      });
    },
    procData() {
      if (this.resourceValue.data === null) {
        return;
      }
      this.process = true;
      for (let i = 0; i < this.resourceValue.data.length; i++) {
        this.procValInd[this.resourceValue.data[i]['attributeId']] = this.resourceValue.data[i];
      }

      this.procValKeys = Object.keys(this.procValInd);
      this.procDataPlain();
      this.procDataOption();
      this.procDataTable();
      this.dataCurrent = this.data;
      this.procFinished = true;
    },
    procDataPlain() {
      const attrs = this.data.plain;

      for (let i = 0; i < attrs.length; i++) {
        if (!this.procValKeys.includes(String(attrs[i]['attributeId']))) {
          this.$set(attrs[i], 'value', attrs[i]['defaultValue']);
          continue;
        }
        this.$set(attrs[i], 'value', this.procValInd[attrs[i]['attributeId']]['attributeValue']);
      }
    },
    procDataOption() {
      const attrs = this.data.option;

      for (let i = 0; i < attrs.length; i++) {
        this.$set(attrs[i], 'optionArr', attrs[i]['options'].split(','));
        if (!this.procValKeys.includes(String(attrs[i]['attributeId']))) {
          this.$set(attrs[i], 'value', attrs[i]['defaultValue']);
        } else {
          this.$set(attrs[i], 'value', this.procValInd[attrs[i]['attributeId']]['attributeValue']);
        }
        let valueInd = -1;
        if (attrs[i]['value'] !== '') {
          for (let j = 0; j < attrs[i]['optionArr'].length; j++) {
            if (attrs[i]['optionArr'][j] === attrs[i]['value']) {
              valueInd = j;
              break;
            }
          }
        }
      }
    },
    procDataTable() {
      const attrs = this.data.table;
      for (let i = 0; i < attrs.length; i++) {
        if (!this.procValKeys.includes(String(attrs[i]['attributeId']))) {
          this.$set(attrs[i], 'value', {"column": [], "data": []});
          continue;
        }
        let data;
        try {
          data = JSON.parse(this.procValInd[attrs[i]['attributeId']]['attributeValue']);

        } catch (e) {
          this.$set(attrs[i], 'value', {"column": [], "data": []});
          continue;
        }
        const keys = Object.keys(data);
        if (!keys.includes('column') || !keys.includes('data')) {
          this.$set(attrs[i], 'value', {"column": [], "data": []});
          continue;
        }

        this.$set(attrs[i], 'value', data);
      }
    },
    edit() {
      this.dataEdit = JSON.parse(JSON.stringify(this.data));
      this.dataCurrent = this.dataEdit;
      this.editing = true;
    },
    cancelEdit() {
      this.dataCurrent = this.data;
      this.editing = false;
    },
    save() {
      let reqList = [];
      for (let i = 0; i < this.dataCurrent.plain.length; i++) {
        reqList.push({
          "resourceId": this.resourceId,
          "attributeId": this.dataCurrent.plain[i].attributeId,
          "attributeValue": this.dataCurrent.plain[i].value
        });
      }
      for (let i = 0; i < this.dataCurrent.option.length; i++) {
        reqList.push({
          "resourceId": this.resourceId,
          "attributeId": this.dataCurrent.option[i].attributeId,
          "attributeValue": this.dataCurrent.option[i].value
        });
      }
      for (let i = 0; i < this.dataCurrent.table.length; i++) {
        reqList.push({
          "resourceId": this.resourceId,
          "attributeId": this.dataCurrent.table[i].attributeId,
          "attributeValue": JSON.stringify(this.dataCurrent.table[i].value)
        });
      }
      console.log(reqList);
      this.dialog.loading = true;
      indexResourceDataDetailsApis.setResourceValue(reqList).then(data => {
        this.dialog.loading = false;
        if (data === null) {
          return;
        }
        this.$message.success('保存成功！');
        this.dataCurrent = this.data;
        this.editing = false;
        this.init();
      });
    },
    dialogEditColumn(current) {
      console.log(JSON.parse(JSON.stringify(current)));
      this.dialog.title = '编辑表头 - ' + current.displayName;
      this.dialog.column = JSON.parse(JSON.stringify(current.value.column));
      this.dialog.columnCurrent = current;
      this.dialog.visible = true;
    },
    columnAdd() {
      this.dialog.column.push('');
    },
    columnRemove(ind) {
      this.dialog.column.splice(ind, 1);
    },
    columnSave() {
      if (this.dialog.column.includes('')) {
        this.$message.error('表头不能为空');
        return;
      }
      this.dialog.columnCurrent.value.column = JSON.parse(JSON.stringify(this.dialog.column));
      this.dialog.visible = false;
      this.$message.success("修改成功");
    },
    tableEdit(current) {
      this.$set(current, 'valueEdit', JSON.parse(JSON.stringify(current.value)));
      const columnLength = current['valueEdit'].column.length;
      for (let i = 0; i < current['valueEdit']['data'].length; i++) {
        const row = current['valueEdit']['data'][i];
        if (row.length < columnLength) {
          for (let j = 0; j <= columnLength - row.length; j++) {
            row.push('');
          }
        } else if (row.length > columnLength) {
          this.$set(current['valueEdit']['data'], i, row.splice(0, columnLength));
        }
      }
      this.$set(current, 'editing', true);
    },
    tableSave(current) {
      this.$set(current, 'value', JSON.parse(JSON.stringify(current['valueEdit'])));
      this.$set(current, 'editing', false);
      this.$message.success("修改成功");
    },
    tableAddRow(current) {
      const colLength = current['valueEdit'].column.length;
      let arr = [];
      for (let i = 0; i < colLength; i++) {
        arr.push('');
      }
      current['valueEdit'].data.push(arr);
    },
    tableDelLine(current, index) {
      current['valueEdit'].data.splice(index, 1);
    }
  },

  mounted() {
    console.log(this);
    this.init();
  },
  watch: {
    '$route.params.id': 'init',
    'resource.data': function (val, oldVal) {
      if (val !== null) {
        this.getResourceAttribute();
        this.getResourceValue();
      }
    },
    'resourceAttribute.finished': function (val, oldVal) {
      if (val && this.resourceValue.finished && !this.process) {
        this.procData();
      }
    },
    'resourceValue.finished': function (val, oldVal) {
      if (val && this.resourceAttribute.finished && !this.process) {
        this.procData();
      }
    }
  },
  computed: {}
}
</script>

<style scoped>
.details-div > div {
  padding: 0 10px;
  width: calc(100% - 20px);
  margin-top: 10px;
}

.dialog-form-column > div {
  margin-bottom: 10px;
}

</style>