import api from "@/request/api";

export default {
  getOrder(pageNo, pageSize, orderStatus) {
    return api.post('/itsm/order/get', {
      pageNo, pageSize, orderStatus
    });
  },

};
