<template>
  <div>
    <table style="width: 100%; height: 40px;">
      <tbody style="width: 100%">
      <tr>
        <td>
          <el-button type="success" size="small" @click="addTypeEvent(null)">新增类型</el-button>
          <el-button size="small" @click="getType">刷新</el-button>
        </td>
        <td style="text-align: right">

        </td>
      </tr>
      </tbody>
    </table>
    <div style="margin-top: 20px; overflow-x: auto; height: calc(100% - 70px);">
      <el-table
          :data="typeData"
          style="height: 100%;"
          row-key="typeId"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        >
        <el-table-column prop="typeName" label="名称"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="addTypeEvent(scope.row['typeId'])"
                       v-if="scope.row['parentTypeId']===-1">新增
            </el-button>
            <el-button size="small" type="text" @click="setTypeEvent(scope.row['typeId'], scope.row['typeName'])">修改
            </el-button>
            <el-button size="small" type="text" @click="delType(scope.row['typeId'], scope.row['typeName'])">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="dialog.title"
        :visible.sync="dialog.visible"
        width="30%"
        :destroy-on-close="true">
      <span>
        <el-input v-model="dialog.input" placeholder="类型名称"></el-input>
      </span>
      <span slot="footer">
        <el-button @click="dialog.visible = false">取消</el-button>
        <el-button type="primary" @click="dialogEvent" :loading="dialog.loading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import indexResourceTypeApis from '@/apis/index-resource/index-resource-type-apis';

export default {
  name: 'IndexResourceType',
  data() {
    return {
      typeData: [],
      typeDataRootIndex: {},
      dialog: {
        title: '',
        visible: false,
        input: '',
        typeId: -1,
        parentId: -1,
        loading: false
      }
    };
  },
  methods: {
    getType() {
      this.typeData = [];
      indexResourceTypeApis.getType().then((data) => {
        if (data === null) {
          return;
        }
        let rootType = [];
        let childType = [];
        const row = data['data'];
        for (let i = 0; i < row.length; i++) {
          if (row[i]['parentTypeId'] === -1) {
            rootType.push(row[i]);
          } else {
            childType.push(row[i]);
          }
        }
        for (let i = 0; i < rootType.length; i++) {
          this.typeData.push(rootType[i]);
          this.typeDataRootIndex[rootType[i]['typeId']] = this.typeData[this.typeData.length - 1];
          this.typeDataRootIndex[rootType[i]['typeId']].children = [];
        }
        const typeRootIndexKey = Object.keys(this.typeDataRootIndex);
        for (let i = 0; i < childType.length; i++) {
          if (!typeRootIndexKey.includes(childType[i]['parentTypeId'].toString())) {
            console.log('data error: ', childType[i]);
            continue;
          }
          this.typeDataRootIndex[childType[i]['parentTypeId']].children.push(childType[i]);
        }
      });
    },
    addTypeEvent(sub) {
      this.dialog.title = '新增';
      this.dialog.input = '';
      if (sub !== null) {
        this.dialog.parentId = sub;
      } else {
        this.dialog.parentId = -1;
      }
      this.dialog.visible = true;
      console.log(this.dialog)
    },
    setTypeEvent(sub, txt) {
      this.dialog.title = '修改';
      this.dialog.input = txt;
      if (sub !== null) {
        this.dialog.typeId = sub;
      } else {
        return;
      }
      this.dialog.visible = true;
      console.log(this.dialog)
    },
    dialogEvent() {
      if (this.dialog.title === '新增') {
        this.addType();
      } else if (this.dialog.title === '修改') {
        this.setType();
      }
    },
    addType() {
      if (this.dialog.input === "") {
        this.$message.error("请输入“类型名称”")
        return;
      }
      indexResourceTypeApis.addType(this.dialog.input, this.dialog.parentId).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("新增成功");
        this.dialog.visible = false;
        this.getType();
      });
    },
    setType() {
      if (this.dialog.input === "") {
        this.$message.error("请输入“类型名称”")
        return;
      }
      indexResourceTypeApis.setType(this.dialog.typeId, this.dialog.input).then(data => {
        if (data === null) {
          return;
        }
        this.$message.success("修改成功");
        this.dialog.visible = false;
        this.getType();
      });
    },
    delType(sub, txt) {
      this.$confirm('确认要删除“' + txt + '”吗？').then(() => {
        indexResourceTypeApis.delType(sub).then(data => {
          if (data === null) {
            return;
          }
          this.$message.success("删除成功");
          this.getType();
        });
      }).catch(() => {
      });
    }
  },
  mounted() {
    this.getType();
  }
}
</script>

<style scoped>

</style>