import api from "@/request/api";

export default {
  getOrderById(orderId) {
    return api.post('/itsm/order/get-by-id/' + orderId);
  },
  passOrder(orderId, reply, remark) {
    return api.post('/itsm/order/submit', {
      submitType: 0, orderId, reply, remark
    });
  },
  returnOrder(orderId, remark) {
    return api.post('/itsm/order/submit', {
      submitType: 1, orderId, remark
    });
  },
  resubmitOrder(orderId, data) {
    return api.post('/itsm/order/submit', {
      submitType: 3, orderId, data
    });
  },
  commentOrder(orderId, comment, evaluate) {
    return api.post('/itsm/order/submit', {
      submitType: 2, orderId, comment, evaluate
    });
  },
  getLog(orderId) {
    return api.post('/itsm/log/get/by-order/' + orderId);
  }
};
