import { render, staticRenderFns } from "./IndexITSMAdminTemplateField.vue?vue&type=template&id=5721cdec&scoped=true&"
import script from "./IndexITSMAdminTemplateField.vue?vue&type=script&lang=js&"
export * from "./IndexITSMAdminTemplateField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5721cdec",
  null
  
)

export default component.exports