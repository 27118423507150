<template>
  <div style="width: 100%; height: 100%" ref="body">

    <div style="height: 40px">
      <p style="font-weight: bold; margin-left: 5px; line-height: 40px; color: #606266; font-size: 14px; margin-right: 5px; user-select: none; cursor: default">
        {{ "工单 / " + (this.processed ? '已办' : '待办') }}
      </p>
    </div>
    <el-table :height="tableHeight" :data="data">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="orderName" label="名称"></el-table-column>
      <el-table-column prop="templateName" label="流程名称"></el-table-column>
      <el-table-column prop="userName" label="发起人"></el-table-column>
      <el-table-column prop="orderStatusName" label="当前状态"></el-table-column>
      <el-table-column prop="currentUserName" label="当前处理人"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="100"><!--功能-->
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="choose(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @size-change="tableSizeChange"
        @current-change="tablePageChange"
        :current-page="pageNo"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        style="margin-top: 10px; height: 36px; float: right;"
        :total="pageTotal">
    </el-pagination>
  </div>
</template>
<script>

import indexItsmAdminOrderApis from "@/apis/index-itsm/index-itsm-admin-order-apis";

export default {
  name: 'IndexITSMAdminOrder',
  data() {
    return {
      path: {
        pending: [false, '待办'],
        done: [true, '已办'],
      },
      orderName: {
        0: '进行中',
        1: '待评价',
        2: '已退回',
        3: '已结束',
      },
      processed: null,
      data: null,
      tableHeight: null,
      pageNo: 1,
      pageSize: 20,
      pageTotal: 0,
    };
  },
  methods: {
    init() {
      Object.assign(this.$data, this.$options.data.call(this));
      if (this.$route.params['type'] !== "done" && this.$route.params['type'] !== "pending") {
        return;
      }
      this.processed = this.$route.params['type'] === "done";
      this.getOrder();
    },
    getOrder() {
      indexItsmAdminOrderApis.getOrder(this.processed, this.pageNo, this.pageSize).then(data => {
        if (data === null) {
          return;
        }

        this.pageNo = data['data']['pageNo'];
        this.pageSize = data['data']['pageSize'];
        this.pageTotal = data['data']['totalSize'];
        for (let i = 0; i < data['data']['rows'].length; i++) {
          const row = data['data']['rows'][i];
          if (this.orderName.hasOwnProperty(row['orderStatus'])) {
            row['orderStatusName'] = this.orderName[row['orderStatus']];
          }
        }
        this.data = data['data']['rows'];
      });
    },
    choose(row) {
      console.log(row);
      this.$router.push('/index/itsm/order-details/' + row['orderId']);
    },
    tablePageChange(newPageNo) {
      this.pageNo = newPageNo;
      this.getOrder();
    },
    tableSizeChange(newPageSize) {
      this.pageNo = 1;
      this.pageSize = newPageSize;
      this.getOrder();
    },
    tableResize() {
      if (!this.$refs.body) {
        this.tableHeight = "0";
      } else {
        this.tableHeight = this.$refs.body.offsetHeight - 100 + "px";
      }
    },
  },
  mounted() {
    this.init();
    window.addEventListener('resize', this.tableResize);
    this.$nextTick(() => {
      this.tableResize();
    });
  },
  watch: {
    '$route.params.type': 'init'
  }
}
</script>

<style scoped>

</style>